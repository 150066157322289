import * as moment from 'moment';
import { BaseModel } from './base.model';

export enum DsformStatus {
    Active = 1,
    Draft = 2,
    Inactive = 3,
}

export class FileUploadModel {
    public name: string;
    public type: string;
    public dataUrl: string;
    public isImage: boolean;
}

export enum DsformFileUploadAllowedExt {
    doc = '.doc',
    docx = '.docx',
    pdf = '.pdf',
    txt = '.txt',
    rtf = '.rtf',
    img = '.img',
    bmp = '.bmp',
    jpeg = '.jpeg',
    jpg = '.jpg',
    png = '.png',
    xls = '.xls',
    xlsx = '.xlsx',
    csv = '.csv',
}

export class ShortCodeModel extends BaseModel<ShortCodeModel> {
    public id: string;
    public referenceId: string;
    public customerId?: number;
    public shortCodeFormList: [ShortCodeFormModel];

    public static deserialize(obj: any): ShortCodeModel {
        return new ShortCodeModel({
            id: obj['ID'],
            referenceId: obj['ReferenceID'],
            customerId: !isNaN(parseInt(obj['CustomerID'])) ? parseInt(obj['CustomerID']) : undefined,
            shortCodeFormList: obj['ShortCodeFormList']
                ? obj['ShortCodeFormList'].map((o: any) => ShortCodeFormModel.deserialize(o))
                : undefined,
        });
    }
}

export class ShortCodeFormModel extends BaseModel<ShortCodeFormModel> {
    public dateTimeSubmitted: Date;
    public formTemplate: DsformModel;
    public userForm: DsformModel;

    public static deserialize(obj: any): ShortCodeFormModel {
        return new ShortCodeFormModel({
            dateTimeSubmitted: obj['DateTimeSubmitted'] ? new Date(obj['DateTimeSubmitted']) : undefined,
            formTemplate: obj['FormTemplate'] ? DsformModel.deserialize(obj['FormTemplate']) : undefined,
            userForm: obj['UserForm'] ? DsformModel.deserialize(obj['UserForm']) : undefined,
        });
    }
}

export class DsformModel extends BaseModel<DsformModel> {
    public id?: string;
    public status: DsformStatus;
    public name: string;
    public title: string;
    public header: string;
    public subheader: string;
    public dateTimeCreated?: moment.Moment;
    public dateTimeModified: moment.Moment;
    public dateTimeSubmitted?: moment.Moment;
    public themeColor: string;
    public logoUrl: string;
    public questions: DsformModelQuestion[];

    public static ensureHttps(url: string): string {
        const valid = 'https://';
        const invalid = 'http://';
        if (url && !url.startsWith(valid) && url.startsWith(invalid)) {
            return url.replace(invalid, valid);
        }

        return url;
    }

    public static deserialize(obj: any): DsformModel {
        return new DsformModel({
            id: obj['Ulid'],
            status: obj['Status'],
            name: obj['Name'],
            title: obj['Title'],
            header: obj['Header'],
            subheader: obj['Subheader'],
            dateTimeCreated: moment(obj['DateTimeCreated']),
            dateTimeModified: moment(obj['DateTimeModified']),
            dateTimeSubmitted: obj['DateTimeSubmitted']?.toString().length > 0 ? moment(obj['DateTimeSubmitted']) : undefined,
            themeColor: obj['ThemeColor'],
            logoUrl: DsformModel.ensureHttps(obj['LogoUrl']),
            questions: obj['Questions']
                ? obj['Questions'].map((e: any) => DsformModelQuestion.deserialize(e))
                : obj['QuestionList']
                ? obj['QuestionList'].map((e: any) => DsformModelQuestion.deserialize(e))
                : [],
        });
    }

    public static fromFormTemplate(formTemplate: any): DsformModel {
        //Dsform model is going to represent an individual form a user is filling out, so it's not
        //technically a form template, even through the fields almost match identically.  Form templates don't have
        //ids for questions/options, and the rendering functionality expects ids to create unique form groups, so we'll
        //generate temp ids here
        const model = DsformModel.deserialize(formTemplate);
        let counter = 1;
        model.questions?.forEach((q) => {
            q.id = `q_${counter++}`;
            q.optionList?.forEach((o) => {
                o.id = `o_${counter++}`;
            });
        });
        return model;
    }

    public static generatePlaceholderForm(): DsformModel {
        return new DsformModel({
            status: DsformStatus.Draft,
            logoUrl: undefined,
            themeColor: 'f1f3f4',
        });
    }
}
export class DsformModelQuestion extends BaseModel<DsformModelQuestion> {
    public id?: string;
    public type?: DsformElementType;
    public question: string;
    public index?: number;
    public required?: boolean;
    public deleted?: boolean;
    public optionList: DsformModelQuestionOption[];

    public static deserialize(obj: any): DsformModelQuestion {
        return new DsformModelQuestion({
            id: obj['Ulid'],
            type: obj['Type'],
            question: obj['Question'],
            index: obj['Index'],
            required: obj['Required'] || false,
            deleted: obj['Deleted'],
            optionList: obj['OptionList'] ? obj['OptionList'].map((e: any) => DsformModelQuestionOption.deserialize(e, obj['Type'])) : [],
        });
    }
}
export class DsformModelQuestionOption extends BaseModel<DsformModelQuestionOption> {
    public id?: string;
    public index?: number;
    public value: string;
    public type: string;
    public answerCount?: number;
    public deleted?: boolean;
    public answer: boolean | string | number;

    public static deserialize(obj: any, elementType: DsformElementType): DsformModelQuestionOption {
        const option = new DsformModelQuestionOption({
            id: obj['Ulid'],
            index: obj['Index'],
            value: obj['Value'],
            type: obj['Type'],
            answer: obj['Answer'],
            answerCount: obj['AnswerCount'],
            deleted: obj['Deleted'],
        });
        const rawAnswer = obj['Answer'];
        if (rawAnswer && typeof rawAnswer === 'string' && this.IsSelectType(elementType)) {
            if (rawAnswer.toLowerCase() === 'true' || rawAnswer.toLowerCase() === 'false') {
                option.answer = rawAnswer.toLowerCase() === 'true';
            } else if (!isNaN(rawAnswer as any)) {
                option.answer = parseFloat(rawAnswer);
            }
        }
        return option;
    }

    private static IsSelectType(elementType: DsformElementType): boolean {
        return (
            elementType === DsformElementType.Checkbox ||
            elementType === DsformElementType.RadioButton ||
            elementType === DsformElementType.Dropdown ||
            elementType === DsformElementType.Scale
        );
    }
}

export enum DsformItemType {
    Product,
    Service,
    Package,
    Membership,
}

export enum DsformElementType {
    ShortAnswer,
    LongAnswer,
    ClientInfo,
    RadioButton,
    Checkbox,
    Dropdown,
    Scale,
    Date,
    Time,
    FileUpload,
    Signature,
    Header,
    Paragraph,
    Separator,
    Image,
}

export enum FileUploadType {
    Logo = 1,
    Image = 2,
    Signature = 3,
    File = 4,
}

export class DsformElementModel extends BaseModel<DsformElementModel> {
    public name: string;
    public icon: string;
    public type: DsformElementType;
    public data: DsformElementData;
    public uniqueIdentifier: string;
    public required: boolean;

    public static dsformQuestionElements(): DsformElementModel[] {
        return [
            new DsformElementModel({
                name: 'Short answer',
                icon: 'short_text',
                type: DsformElementType.ShortAnswer,
                required: false,
            }),
            new DsformElementModel({
                name: 'Long answer',
                icon: 'notes',
                type: DsformElementType.LongAnswer,
                required: false,
            }),
            new DsformElementModel({
                name: 'Client info',
                icon: 'contact_phone',
                type: DsformElementType.ClientInfo,
                required: false,
            }),
            new DsformElementModel({
                name: 'Radio button',
                icon: 'radio_button_checked',
                type: DsformElementType.RadioButton,
                required: false,
            }),
            new DsformElementModel({
                name: 'Checkbox',
                icon: 'check_box',
                type: DsformElementType.Checkbox,
                required: false,
            }),
            new DsformElementModel({
                name: 'Dropdown',
                icon: 'arrow_drop_down_circle',
                type: DsformElementType.Dropdown,
                required: false,
            }),
            new DsformElementModel({
                name: 'Scale',
                icon: 'linear_scale',
                type: DsformElementType.Scale,
                required: false,
            }),
            new DsformElementModel({
                name: 'Date',
                icon: 'today',
                type: DsformElementType.Date,
                required: false,
            }),
            new DsformElementModel({
                name: 'Time',
                icon: 'access_time',
                type: DsformElementType.Time,
                required: false,
            }),
            new DsformElementModel({
                name: 'File Upload',
                icon: 'cloud_upload',
                type: DsformElementType.FileUpload,
                required: false,
            }),
            new DsformElementModel({
                name: 'Signature',
                icon: 'gesture',
                type: DsformElementType.Signature,
                required: false,
            }),
        ];
    }

    public static dsformTextElements(): DsformElementModel[] {
        return [
            new DsformElementModel({
                name: 'Header',
                icon: 'title',
                type: DsformElementType.Header,
                required: false,
            }),
            new DsformElementModel({
                name: 'Paragraph',
                icon: 'format_size',
                type: DsformElementType.Paragraph,
                required: false,
            }),
            new DsformElementModel({
                name: 'Separator',
                icon: 'horizontal_rule',
                type: DsformElementType.Separator,
                required: false,
            }),
            new DsformElementModel({
                name: 'Image',
                icon: 'insert_photo',
                type: DsformElementType.Image,
                required: false,
            }),
        ];
    }
}

export interface DsformElementData {
    question: DsformModelQuestion;
}
