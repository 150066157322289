import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DsformModel, DsformStatus } from '../dsform.model';
import { DsformService } from '../dsform.service';
import { DisplayChoice } from '../dsforms-content/populate-form/display-choice.enum';
import { FORM_JUST_SUBMITTED_MESSAGE } from '../dsforms-content/populate-form/submitted-form-messages';

const DEFAULT_THEME_COLOR = '#d83a4b';

@Component({
    selector: 'dsf-dsforms-form-layout',
    templateUrl: './dsforms-form-layout.component.html',
    styleUrls: ['./dsforms-form-layout.component.scss'],
})
export class DsformsFormLayoutComponent {
    public forms: DsformModel;
    public ulid = '';
    public formToPopulate: DsformModel;
    public displayChoice = DisplayChoice.ReadWrite;
    public messageToDisplay: string;
    public formNotFound = false;
    public isSaving = false;

    constructor(private route: ActivatedRoute, private dsformService: DsformService, private dialog: MatDialog) {
        this.ulid = this.route.snapshot.params.ulid;
        // call dsform service to get form for client to fill
        this.dsformService
            .getFormTemplateByUlid(this.ulid)
            .pipe(take(1))
            .subscribe((response: ApiResponseModel<DsformModel>) => {
                if (response.error) {
                    this.formToPopulate = new DsformModel({ themeColor: DEFAULT_THEME_COLOR });
                    this.formNotFound = true;
                } else {
                    const responseForm = response?.data;
                    if (!responseForm || responseForm.status !== DsformStatus.Active) {
                        this.formToPopulate = new DsformModel({ themeColor: DEFAULT_THEME_COLOR });
                        this.formNotFound = true;
                    } else {
                        this.formToPopulate = responseForm;
                    }
                }
            });
    }

    public onSave(formToSubmit: DsformModel) {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        const userFormToSubmit = new DsformModel({
            ...formToSubmit,

            questions: formToSubmit.questions.filter((q) => q.id),
        });
        this.dsformService
            .submitUserForm(userFormToSubmit, '')
            .toPromise()
            .then((response: ApiResponseModel<DsformModel>) => {
                this.isSaving = false;
                if (response.error) {
                    this.dialog
                        .open(DialogComponent, {
                            width: '500px',
                            panelClass: 'error-dialog',
                            data: {
                                themeColor: this.formToPopulate?.themeColor,
                                title: 'Error',
                                content: response.statusMessage || 'Unable to save the form. Please try again.',
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    return;
                }
                this.displayChoice = DisplayChoice.MessageOnly;
                this.messageToDisplay = FORM_JUST_SUBMITTED_MESSAGE;
            });
    }
}
