import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DsformElementModel, DsformModel, DsformModelQuestion } from '../../dsform.model';
import { UntypedFormBuilder } from '@angular/forms';
import { DsformsPopulateFormElementComponent } from './populate-form-element/populate-form-element.component';
import { DisplayChoice } from './display-choice.enum';

@Component({
    selector: 'dsf-populate-form',
    templateUrl: './populate-form.component.html',
    styleUrls: ['./populate-form.component.scss'],
})
export class DsformsPopulateFormComponent implements OnInit, OnChanges {
    @Input() public formToPopulate: DsformModel;
    @Input() public formNotFound: boolean;
    @Input() public displayChoice: DisplayChoice;
    @Input() public messageToDisplay: string;
    private _isSaving: boolean;
    public get isSaving(): boolean {
        return this._isSaving;
    }
    @Input() public set isSaving(val: boolean) {
        const oldVal = this._isSaving;
        this._isSaving = val;
        if (val !== oldVal) {
            this.checkForDisableForm();
        }
    }
    @Output() public save = new EventEmitter();

    @ViewChildren(DsformsPopulateFormElementComponent)
    public elementComponents: QueryList<DsformsPopulateFormElementComponent>;
    public dsform: UntypedFormGroup;
    public dsformElementOptions = [...DsformElementModel.dsformQuestionElements(), ...DsformElementModel.dsformTextElements()];
    public formElements: DsformElementModel[] = [];

    constructor(private fb: UntypedFormBuilder, private element: ElementRef) {}

    ngOnInit(): void {
        if (!this.formNotFound) {
            this.generateDsform();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formToPopulate && this.formToPopulate && !this.formNotFound) {
            this.generateDsform();
        }
    }

    private generateDsform() {
        this.formElements = [];
        this.dsform = this.fb.group({});
        if (this.formToPopulate.questions?.length > 0) {
            // for each question, call addElement
            this.formToPopulate.questions.forEach((question: DsformModelQuestion) => {
                const element = this.dsformElementOptions.find((e) => e.type === question.type);

                if (element) {
                    element.data = {
                        question: question,
                    };
                    this.addElement(element);
                }
            });
        }
    }

    public isReadOnly(): boolean {
        return this.displayChoice === DisplayChoice.ReadOnly || this.displayChoice === DisplayChoice.PreviewReadOnly || this.isLoading();
    }

    public isPreview(): boolean {
        return this.displayChoice === DisplayChoice.PreviewReadOnly || this.displayChoice === DisplayChoice.PreviewReadWrite;
    }

    public isMessageOnly(): boolean {
        return this.displayChoice === DisplayChoice.MessageOnly;
    }

    public isSubmitShown(): boolean {
        return this.displayChoice === DisplayChoice.ReadWrite || this.isPreview();
    }

    public isLoading(): boolean {
        return this.displayChoice === DisplayChoice.Loading;
    }

    public addElement(element: DsformElementModel): void {
        const newElement = new DsformElementModel({
            ...element,
        });
        this.formElements.push(newElement);
    }

    public submitForm(): void {
        const userFormWithAnswers = new DsformModel({
            id: this.formToPopulate.id,
            questions: this.elementComponents.map((c) => {
                return new DsformModelQuestion({
                    ...c.generateModel(),
                });
            }),
        });
        if (this.isSaving) {
            return;
        }
        if (!this.dsform.valid) {
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            });
            return;
        }
        this.save.emit(userFormWithAnswers);
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.element.nativeElement.querySelector('form .mat-error');

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth',
        });
    }

    private getTopOffset(controlElement: HTMLElement): number {
        const labelOffset = 50;
        return controlElement.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    private checkForDisableForm(): void {
        if (this.isSaving) {
            this.dsform?.disable();
        } else {
            this.dsform?.enable();
        }
    }
}
