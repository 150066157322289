<h1 mat-dialog-title [innerHTML]="data.title" class="dialog-title"></h1>
<div mat-dialog-content class="dialog-content">
    <p [innerHTML]="data.content ? data.content : ''"></p>
</div>

<div class="ok-button" mat-dialog-actions>
    <button [style.background]="data.themeColor || 'red'" type="button" mat-flat-button (click)="onClick()" qa="ok-button">
        <div>OK</div>
    </button>
</div>
