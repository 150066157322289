import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-short-answer',
    templateUrl: './dsforms-short-answer.component.html',
    styleUrls: ['./dsforms-short-answer.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsShortAnswerComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public hasError = false;
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }
    public get answer(): string {
        const val = this.formGroup?.get('answer')?.value;
        return val ? val : 'Answer';
    }
    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        const answer = this.data?.question?.optionList[0]?.answer || '';

        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.formGroup = new UntypedFormGroup({
                answer: new UntypedFormControl(answer, [Validators.required]),
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                answer: new UntypedFormControl(answer),
            });
        }
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        if (this.data?.question?.optionList[0]) {
            this.data.question.optionList[0].answer = this.formGroup?.get('answer')?.value;
        } else {
            this.data.question.optionList = [
                new DsformModelQuestionOption({
                    answer: this.formGroup?.get('answer')?.value,
                }),
            ];
        }
        return this.data?.question;
    }

    private validate() {
        const answer = this.formGroup?.get('answer')?.value;
        if (answer) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }
}
