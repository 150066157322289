<div class="element-form-container dsforms-element-dropdown-container" [formGroupName]="formGroupName" qa="dropdown-element">
    <div class="dropdown-question element-question-static" qa="dropdown-question">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div class="dropdown-options-container">
        <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer" floatLabel="never">
            <mat-select placeholder="Select an Option" formControlName="dropdown" qa="dropdown-dropdown">
                <mat-option *ngFor="let option of options; index as i" [value]="option.value" qa="dropdown-option-{{ option.value }}">
                    {{ option.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer" floatLabel="never">
            <select matNativeControl placeholder="Select an Option" formControlName="dropdown" qa="native-dropdown-dropdown">
                <option *ngFor="let option of options; index as i" [ngValue]="option.value" qa="native-dropdown-option-{{ option.value }}">
                    {{ option.value }}
                </option>
            </select>
        </mat-form-field>
        <mat-error qa="error" *ngIf="hasError">One option needs to be selected.</mat-error>
    </div>
</div>
