import { Component, OnInit } from '@angular/core';
import { EnvConfigLoaderService } from './services/env-config-loader.service';

@Component({
    selector: 'dsf-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'dsforms';
    constructor(private envConfigLoader: EnvConfigLoaderService) {}

    ngOnInit() {
        this.appendVersionInfo();
    }

    /**
     * Appends the deploy time and short commit hash in the html as a comment after the <app-root> element.
     */
    appendVersionInfo() {
        const config = this.envConfigLoader.getEnvConfig();
        if (config && config.commitDateTime) {
            const commitDate = new Date(config.commitDateTime);
            const formatedCommitDate =
                commitDate.getFullYear() +
                '.' +
                (commitDate.getMonth() + 1).toString().padStart(2, '0') +
                '.' +
                commitDate.getDate().toString().padStart(2, '0');
            const versionHtmlComment = `${formatedCommitDate}-${config.commitHashShort}`;
            const rootElement = document.querySelector('dsf-app-root');
            if (rootElement) {
                rootElement.insertAdjacentHTML(
                    'afterend',
                    `
                    <!-- BUILD NUMBER: ${versionHtmlComment} -->
                    <!-- Deploy Time: ${config.deployTimeISO} -->
                    <!-- BUILD ENVIRONMENT: ${config.env} -->
                `
                );
            }
        }
    }
}
