<div class="element-form-container dsforms-element-short-answer-container" formGroupName="{{ formGroupName }}" qa="short answer-element">
    <div class="short-answer-question element-question-static">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <!-- <mat-error qa="error" *ngIf="hasError">Answer is required. </mat-error> -->
    <mat-form-field class="short-answer-answer element-answer" [floatLabel]="'never'">
        <input
            matInput
            type="text"
            autocomplete="off"
            maxlength="50"
            placeholder="Your answer"
            formControlName="answer"
            qa="input-answer"
        />
    </mat-form-field>
    <mat-error qa="error" *ngIf="hasError">Answer is required. </mat-error>
</div>
