import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { DynamicElementComponent } from '../populate-form-element.component';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';

@Component({
    selector: 'dsf-dsforms-checkbox',
    templateUrl: './dsforms-checkbox.component.html',
    styleUrls: ['./dsforms-checkbox.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsCheckboxComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public hasError = false;
    public get optionControls(): UntypedFormControl[] {
        return (<UntypedFormArray>this.formGroup?.get('options')).controls as UntypedFormControl[];
    }
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }
    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        const optionControls: UntypedFormControl[] = [];

        if (this.data) {
            const options = this.data?.question?.optionList;
            options?.forEach((option: DsformModelQuestionOption) => {
                const formCtrl = new UntypedFormControl(option.answer === true);
                optionControls.push(formCtrl);
            });
        }

        this.formGroupName = `${this.data?.question?.id}`;
        this.formGroup = new UntypedFormGroup({
            options: new UntypedFormArray(optionControls),
        });
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);
    }

    public getOptionText(idx: number): string {
        const optionVal = this.data.question.optionList[idx].value;
        if (!optionVal) {
            return 'Option';
        }
        return optionVal;
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        this.data?.question?.optionList.forEach((o: any, idx: number) => {
            if (this.formGroup?.value.options[idx]) {
                o.answer = this.formGroup?.value.options[idx];
            }
        });
        return this.data?.question;
    }

    private validate() {
        let oneselected = false;
        this.data?.question?.optionList.forEach((o: any, idx: number) => {
            if (this.formGroup?.value.options[idx]) {
                oneselected = true;
            }
        });
        if (oneselected) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }
}
