import { AfterViewInit, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { DsformModel } from '../dsform.model';
import { DisplayChoice } from '../dsforms-content/populate-form/display-choice.enum';

@Component({
    selector: 'dsf-dsforms-preview-layout',
    templateUrl: './dsforms-preview-layout.component.html',
    styleUrls: ['./dsforms-preview-layout.component.scss'],
})
export class DsformsPreviewLayoutComponent implements AfterViewInit {
    public formToPopulate: DsformModel;
    public displayChoice = DisplayChoice.PreviewReadOnly;
    public formNotFound = false;
    private _MAX_PAGE_LOADED_WAIT_TIME_MILLISECONDS = 5000;
    private _PAGE_LOADED_WAIT_TIME_INTERVAL_MILLISECONDS = 500;

    constructor(private cdRef: ChangeDetectorRef) {
        console.log('[DSFormsAngular] - constructor generating placeholder form.');
        this.formToPopulate = DsformModel.generatePlaceholderForm();
    }

    ngAfterViewInit() {
        console.log('[DSFormsAngular] - ngAfterViewInit start.');
        if (!window) {
            return;
        }

        if (window.opener) {
            console.log('[DSFormsAngular] - ngAfterViewInit posting READY message to opener.');
            window.opener.postMessage('READY', '*');
        } else if (window.parent) {
            console.log('[DSFormsAngular] - ngAfterViewInit posting READY message to parent.');
            window.parent.postMessage('READY', '*');
        }
    }

    @HostListener('window:message', ['$event'])
    public onMessageReceived(event: any) {
        console.log('[DSFormsAngular] - onMessageReceived start.');
        if (event && event.data && event.data.FormTemplate) {
            console.log('[DSFormsAngular] - onMessageReceived event has form template data, constructing form.');
            const newTemplate = DsformModel.fromFormTemplate(event.data.FormTemplate);
            this.formToPopulate = newTemplate;
            this.formNotFound = false;
            let needToCheckPageLoadedState = false;

            if (event.data.Loading) {
                this.displayChoice = DisplayChoice.Loading;
            } else if (this.formToPopulate.dateTimeSubmitted !== undefined && this.formToPopulate.dateTimeSubmitted !== null) {
                this.displayChoice = DisplayChoice.ReadOnly;
                needToCheckPageLoadedState = true;
            } else if (event.data.IsPreviewReadWrite) {
                this.displayChoice = DisplayChoice.PreviewReadWrite;
                needToCheckPageLoadedState = true;
            } else {
                this.displayChoice = DisplayChoice.PreviewReadOnly;
                needToCheckPageLoadedState = true;
            }

            console.log(`[DSFormsAngular] - onMessageReceived needToCheckPageLoadedState: ${needToCheckPageLoadedState}`);
            if (!needToCheckPageLoadedState) {
                this.OnAfterPageLoaded();
            } else {
                // there could be images (custom logo, image component, signature) that don't load immediately
                // PageLoadedCheck() will search for any images on the page and check if they're loaded yet, and
                // if not then wait a small period of time and try again
                // force change detection here so we can queue the PageLoadedCheck() to run at the end of the
                // change detection stack
                console.log('[DSFormsAngular] - onMessageReceived cdRef.detectChanges() call.');
                this.cdRef.detectChanges();
                setTimeout(() => {
                    this.PageLoadedCheck(0);
                }, 0);
            }
        } else {
            //Ignore messages that are not valid
        }
    }

    private PageLoadedCheck(count: number): void {
        console.log(`[DSFormsAngular] - PageLoadedCheck start for iteration: ${count}`);

        if (count * this._PAGE_LOADED_WAIT_TIME_INTERVAL_MILLISECONDS > this._MAX_PAGE_LOADED_WAIT_TIME_MILLISECONDS) {
            this.OnAfterPageLoaded();
        }
        const images = document.getElementsByTagName('img');
        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                if (!images[i].complete) {
                    setTimeout(() => {
                        this.PageLoadedCheck(count + 1);
                    }, this._PAGE_LOADED_WAIT_TIME_INTERVAL_MILLISECONDS); // wait for X and check again
                    return;
                }
            }
        }
        console.log('[DSFormsAngular] - PageLoadedCheck end.');
        this.OnAfterPageLoaded();
    }

    private OnAfterPageLoaded(): void {
        console.log('[DSFormsAngular] - OnAfterPageLoaded start.');
        if (!window) {
            return;
        }

        if (window.opener) {
            console.log('[DSFormsAngular] - OnAfterPageLoaded sending LOADING_COMPLETE to opener.');
            window.opener.postMessage('LOADING_COMPLETE', '*');
        } else if (window.parent) {
            console.log('[DSFormsAngular] - OnAfterPageLoaded sending LOADING_COMPLETE to parent.');
            window.parent.postMessage('LOADING_COMPLETE', '*');
        }
    }
}
