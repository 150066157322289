<div class="element-form-container dsforms-element-signature-container" formGroupName="{{ formGroupName }}" qa="signature-element">
    <div class="signature-question-and-clear-button">
        <div class="signature-question element-question-static" qa="signature-question">
            <span class="question-content">{{ question }}</span>
            <span class="dsform-required-indicator" *ngIf="required">*</span>
        </div>
        <div *ngIf="!signatureImageUrl" class="signature-clear-button">
            <button
                [style.border-color]="themeColor"
                [style.color]="themeColor"
                type="button"
                mat-flat-button
                (click)="clear()"
                qa="button-signature-clear"
            >
                Clear
            </button>
        </div>
    </div>
    <div class="signature-area">
        <ng-container formGroupName="termsAndConditions">
            <div (resized)="onCanvasResized($event)" class="dsforms-signature-canvas-container">
                <canvas
                    class="signature-pad-canvas"
                    [ngClass]="{ hidden: signatureImageUrl }"
                    #sPad
                    (touchend)="collect()"
                    (mouseup)="collect()"
                    qa="signature-canvas"
                ></canvas>
                <div *ngIf="isEmpty() && !signatureImageUrl" class="dsforms-signature-placeholder">Please sign here</div>
                <img *ngIf="signatureImageUrl" [src]="signatureImageUrl" />
            </div>
            <div class="dsforms-signature-checkbox-container">
                <mat-checkbox color="primary" formControlName="agree" qa="signature-agree-checkbox">
                    <div class="signature-checkbox-text">
                        I agree to use electronic signatures in accordance with these
                        <span (click)="openTnC(); $event.stopPropagation(); $event.preventDefault()">terms and conditions</span>.
                    </div>
                </mat-checkbox>
            </div>
        </ng-container>
    </div>
    <mat-error qa="error" *ngIf="hasError">Please sign and agree the terms and condition. </mat-error>
</div>
