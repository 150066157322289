<div class="element-form-container dsforms-element-file-upload-container" formGroupName="{{ formGroupName }}">
    <div class="file-upload-question element-question-static">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div class="dsform-image-upload-container">
        <div
            *ngIf="uploadedFiles.length < 3 && !viewMode"
            class="drag-and-drop-container"
            [ngClass]="{ 'file-dragged-and-dropped': uploadedFiles }"
            dsfDragAndDrop
            (fileDropped)="onFileDropped($event)"
            (overDropZone)="setFileOverDropZone($event)"
        >
            <input
                type="file"
                multiple="multiple"
                accept="{{ fileExtFilter }}"
                #fileDropRef
                id="fileDropRef"
                (change)="fileBrowseHandler($event)"
                formControlName="fileUploads"
            />

            <ng-container>
                <img
                    src="../../../../../assets/images/upload-file-24px.png"
                    srcset="assets/images/upload-file-24px@2x.png 2x, assets/images/upload-file-24px@3x.png 3x"
                />
                <div class="file-browse-text">
                    <span>
                        Drop files here or
                        <label class="dsforms-browse-for-logo" for="fileDropRef">browse</label>
                        to upload (up to 3 files can be uploaded)
                    </span>
                </div>
            </ng-container>
        </div>

        <mat-error qa="error" *ngIf="hasError">{{ errorMsg }} </mat-error>

        <div class="file-names-list">
            <li *ngFor="let file of uploadedFiles">
                <img *ngIf="file.isImage" [src]="file.dataUrl" class="file-img-container" />
                <div class="file-name-container">
                    <div>{{ file.name }}</div>
                    <mat-icon *ngIf="!viewMode" class="remove-file-icon" matTooltip="Remove file" (click)="removeFile(file)"
                        >delete_forever</mat-icon
                    >
                </div>
            </li>
        </div>
    </div>
</div>
