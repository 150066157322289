<div class="element-form-container dsforms-element-radio-button-container" formGroupName="{{ formGroupName }}" qa="radio button-element">
    <div class="radio-button-question element-question-static" qa="radio-button-question">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <mat-radio-group class="radio-button-options-container" formControlName="radio">
        <div class="radio-button-option" *ngFor="let option of options; index as i">
            <mat-radio-button color="primary" [value]="option.value" qa="radio-option-{{ option.value }}">
                {{ option.value }}
            </mat-radio-button>
        </div>
    </mat-radio-group>
    <mat-error qa="error" *ngIf="hasError">At least one option need to be selected. </mat-error>
</div>
