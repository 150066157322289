<div class="element-form-container dsforms-element-long-answer-container" formGroupName="{{ formGroupName }}" qa="long answer-element">
    <div class="long-answer-question element-question-static">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <mat-form-field class="long-answer-answer element-answer">
        <textarea
            #textarea
            matInput
            id="longAnswerInput"
            type="text"
            autocomplete="off"
            maxlength="4000"
            formControlName="answer"
            placeholder="Your answer"
            qa="long-answer-input"
        ></textarea>
    </mat-form-field>

    <mat-error qa="error" *ngIf="hasError">Answer is required. </mat-error>
</div>
