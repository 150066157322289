<div class="element-form-container dsforms-element-checkbox-container" [formGroupName]="formGroupName" qa="checkbox-element">
    <div class="checkbox-question element-question-static" qa="checkbox-question">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div class="checkbox-options-container">
        <div class="checkbox-option" *ngFor="let option of optionControls; index as i">
            <mat-checkbox color="primary" [formControl]="option" qa="checkbox-option-{{ getOptionText(i) }}">
                {{ getOptionText(i) }}
            </mat-checkbox>
        </div>
        <mat-error qa="error" *ngIf="hasError">At least one checkbox need to be selected. </mat-error>
    </div>
</div>
