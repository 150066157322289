import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SignaturePad from 'signature_pad';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { FileRawType } from 'src/app/pages/dsforms/dsform.service';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-signature',
    templateUrl: './dsforms-signature.component.html',
    styleUrls: ['./dsforms-signature.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsSignatureComponent implements DynamicElementComponent, OnInit, AfterViewInit, OnDestroy {
    @ViewChild('sPad', { static: true }) signaturePadElement: { nativeElement: HTMLCanvasElement };
    public signaturePad: SignaturePad;
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public hasError = false;
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }

    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }
    private _onDestroy = new Subject();
    private _invalidFormSub: Subscription;
    public signatureImageUrl: string;
    private _lastRecordedCanvasWidth = 0;

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.formGroup = new UntypedFormGroup({
                termsAndConditions: new UntypedFormGroup({
                    agree: new UntypedFormControl(false),
                    signature: new UntypedFormControl('', [Validators.required]),
                }),
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                termsAndConditions: new UntypedFormGroup({
                    agree: new UntypedFormControl(false),
                    signature: new UntypedFormControl(''),
                }),
            });
        }
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);

        if (this.data) {
            if (this.data?.question?.optionList?.length > 0 && this.data.question.optionList[0].answer) {
                this.signatureImageUrl = this.data.question.optionList[0].answer as string;
                this.formGroup.get('termsAndConditions')?.get('agree')?.setValue(true);
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // intializing the signature page in a timeout, because we can't initialize it before ngAfterViewInit is
            // triggered, and initializing it as part of ngAfterViewInit has some side effects because
            // signature pad initialization performs some DOM manipulation that ultimately results in the dreaded
            // ExpressionChangedAfterItHasBeenCheckedError
            this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
            this.resizeSignaturePadElement();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this._invalidFormSub?.unsubscribe();
    }

    public onCanvasResized(event: ResizedEvent) {
        if (!event || this._lastRecordedCanvasWidth === 0 || this._lastRecordedCanvasWidth === event.newWidth) {
            return;
        }
        this.resizeSignaturePadElement();
    }

    public resizeSignaturePadElement(): void {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.signaturePadElement.nativeElement.width = this.signaturePadElement.nativeElement.offsetWidth * ratio;
        this.signaturePadElement.nativeElement.height = this.signaturePadElement.nativeElement.offsetHeight * ratio;
        this.signaturePadElement.nativeElement.getContext('2d')?.scale(ratio, ratio);
        this.clear();
        this._lastRecordedCanvasWidth = this.signaturePadElement.nativeElement.width;
    }

    public collect(): void {
        this.formGroup?.get('termsAndConditions')?.get('signature')?.setValue(this.signaturePad.toDataURL());
    }
    public clear(): void {
        this.signaturePad.clear();
        this.formGroup?.get('termsAndConditions')?.get('signature')?.setValue('');
    }
    public isEmpty(): boolean {
        return this.signaturePad && this.signaturePad.isEmpty();
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            if (this._invalidFormSub) {
                this._invalidFormSub.unsubscribe();
            }
            this._invalidFormSub = this.formGroup.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
                this.validate();
                if (!this.hasError) {
                    this._invalidFormSub.unsubscribe();
                }
            });
        }
        let dataUrl = '';
        if (this.formGroup.get('termsAndConditions')?.get('agree')?.value) {
            dataUrl = this.formGroup.get('termsAndConditions')?.get('signature')?.value;
        }

        return new DsformModelQuestion({
            ...this.data.question,
            optionList: [
                new DsformModelQuestionOption({
                    type: FileRawType.Signature,
                    value: `signature_${this.data.question.id}.png`,
                    answer: dataUrl,
                }),
            ],
        });
    }

    private validate() {
        const signature = this.formGroup?.get('termsAndConditions')?.get('signature')?.value;
        const agreeTerm = this.formGroup?.get('termsAndConditions')?.get('agree')?.value;
        if (signature && agreeTerm) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }

    public openTnC(): void {
        window.open('https://content.daysmart.com/electronic-signatures-act.pdf', 'blank');
    }
}
