import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsformsCheckboxComponent } from '../dsforms/dsforms-content/populate-form/populate-form-element/checkbox/dsforms-checkbox.component';
import { DsformsContentLayoutComponent } from '../dsforms/dsforms-content/dsforms-content-layout.component';
import { DsformsPopulateFormElementComponent } from '../dsforms/dsforms-content/populate-form/populate-form-element/populate-form-element.component';
import { DsformsPopulateFormComponent } from '../dsforms/dsforms-content/populate-form/populate-form.component';
import { DsformsShortcodeLayoutComponent } from './dsforms-shortcode/dsforms-shortcode-layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from 'src/app/app.md-module';
import { DsformsRadioButtonComponent } from './dsforms-content/populate-form/populate-form-element/radio-button/dsforms-radio-button.component';
import { DsformsLongAnswerComponent } from './dsforms-content/populate-form/populate-form-element/long-answer/dsforms-long-answer.component';
import { DsformsTimeComponent } from './dsforms-content/populate-form/populate-form-element/time/dsforms-time.component';
import { DsformsPreviewLayoutComponent } from './dsforms-preview-form/dsforms-preview-layout.component';
import { DsformService } from './dsform.service';
import { DsformsDropdownComponent } from './dsforms-content/populate-form/populate-form-element/dropdown/dsforms-dropdown.component';
import { DsformsClientInfoComponent } from './dsforms-content/populate-form/populate-form-element/client-info/dsforms-client-info.component';
import { DsformsShortAnswerComponent } from './dsforms-content/populate-form/populate-form-element/short-answer/dsforms-short-answer.component';
import { DsformsHeaderComponent } from './dsforms-content/populate-form/populate-form-element/header/dsforms-header.component';
import { DsformsSeparatorComponent } from './dsforms-content/populate-form/populate-form-element/separator/dsforms-separator.component';
import { DsformsDateComponent } from './dsforms-content/populate-form/populate-form-element/date/dsforms-date.component';
import { MatSelectModule } from '@angular/material/select';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { DsformsParagraphComponent } from './dsforms-content/populate-form/populate-form-element/paragraph/dsforms-paragraph.component';
import { DsformsScaleComponent } from './dsforms-content/populate-form/populate-form-element/scale/dsforms-scale.component';
import { DsformsSignatureComponent } from './dsforms-content/populate-form/populate-form-element/signature/dsforms-signature.component';
import { QaModule } from '@daysmart/angular-qa-module';
import { DsformsFormLayoutComponent } from './dsforms-form/dsforms-form-layout.component';
import { DsformsFileUploadComponent } from './dsforms-content/populate-form/populate-form-element/file-upload/dsforms-file-upload.component';
import { DragAndDropDirective } from 'src/app/directives/drag-and-drop.directive';
import { AngularResizedEventModule } from 'angular-resize-event';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        OverlayModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        PortalModule,
        ScrollingModule,
        QaModule,
        AngularResizedEventModule,
    ],
    declarations: [
        DsformsContentLayoutComponent,
        DsformsShortcodeLayoutComponent,
        DsformsFormLayoutComponent,
        DsformsPreviewLayoutComponent,
        DsformsCheckboxComponent,
        DsformsRadioButtonComponent,
        DsformsPopulateFormElementComponent,
        DsformsPopulateFormComponent,
        DsformsShortAnswerComponent,
        DsformsCheckboxComponent,
        DsformsLongAnswerComponent,
        DsformsHeaderComponent,
        DsformsSeparatorComponent,
        DsformsTimeComponent,
        DsformsDateComponent,
        DsformsClientInfoComponent,
        DsformsDropdownComponent,
        DsformsParagraphComponent,
        DsformsScaleComponent,
        DsformsSignatureComponent,
        DsformsFileUploadComponent,
        DragAndDropDirective,
    ],
    providers: [DsformService],
})
export class DsformsModule {}
