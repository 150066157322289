import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DsformModel } from '../dsform.model';
import { DisplayChoice } from './populate-form/display-choice.enum';

export interface Color {
    name: string;
    hex: string;
    darkContrast: boolean;
}

@Component({
    selector: 'dsf-dsforms-content-layout',
    templateUrl: './dsforms-content-layout.component.html',
    styleUrls: ['./dsforms-content-layout.component.scss'],
})
export class DsformsContentLayoutComponent implements OnChanges {
    @Input() public formToPopulate: DsformModel;
    @Input() public formNotFound: boolean;
    @Input() public displayChoice: DisplayChoice;
    @Input() public messageToDisplay: string;
    @Output() public formSubmitted = new EventEmitter();

    @Input() public isSaving: boolean;
    @Output() public save = new EventEmitter();

    constructor(private titleService: Title) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formToPopulate) {
            this.formToPopulate = changes.formToPopulate.currentValue;

            if (this.formToPopulate?.title?.length) {
                this.titleService.setTitle(`Complete Form - ${this.formToPopulate.title}`);
            }
        }
        this.savePrimaryColor(this.formToPopulate.themeColor);
    }

    private savePrimaryColor(hex: string) {
        const primaryColorPalette = [
            this.getColorObject(hex, '50'),
            this.getColorObject(hex, '100'),
            this.getColorObject(hex, '200'),
            this.getColorObject(hex, '300'),
            this.getColorObject(hex, '400'),
            this.getColorObject(hex, '500'),
            this.getColorObject(hex, '600'),
            this.getColorObject(hex, '700'),
            this.getColorObject(hex, '800'),
            this.getColorObject(hex, '900'),
            this.getColorObject(hex, 'A100'),
            this.getColorObject(hex, 'A200'),
            this.getColorObject(hex, 'A400'),
            this.getColorObject(hex, 'A700'),
        ];

        for (const color of primaryColorPalette) {
            const key1 = `--theme-primary-${color.name}`;
            const value1 = color.hex;
            const key2 = `--theme-primary-contrast-${color.name}`;
            const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
            document.documentElement.style.setProperty(key1, value1);
            document.documentElement.style.setProperty(key2, value2);
        }
    }

    private getColorObject(value: string, name: string): Color {
        return {
            name: name,
            hex: value,
            darkContrast: false,
        };
    }

    public onSave(formToSubmit: DsformModel) {
        this.save.emit(formToSubmit);
    }
}
