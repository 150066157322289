<div class="element-form-container dsforms-element-date-container" formGroupName="{{ formGroupName }}" qa="date-element">
    <div class="date-question element-question-static" qa="date-question">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div class="date-picker-container">
        <mat-form-field floatLabel="never" class="date-picker">
            <input
                matInput
                placeholder="Month / Date / Year"
                type="text"
                autocomplete="off"
                [matDatepicker]="datePicker"
                formControlName="date"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-error qa="error" *ngIf="hasError">Date is required. </mat-error>
    </div>
</div>
