import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
    private config: MatSnackBarConfig;
    constructor(private snackBar: MatSnackBar) {
        this.config = new MatSnackBarConfig();
        this.config = {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 6000,
        };
    }
    public open(message: string, action?: string, configuration?: any) {
        if (!action) {
            action = '';
        }
        if (configuration) {
            Object.assign(this.config, configuration);
        }
        return this.snackBar.open(message, action, this.config);
    }

    public dismiss() {
        this.snackBar.dismiss();
    }
}
