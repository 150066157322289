import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-date',
    templateUrl: './dsforms-date.component.html',
    styleUrls: ['./dsforms-date.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsDateComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public hasError = false;
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }

    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        const answer = this.data?.question?.optionList[0]?.answer?.toString().length
            ? new Date(`${this.data.question.optionList[0].answer.toString()}T00:00:00`).toISOString()
            : '';
        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.formGroup = new UntypedFormGroup({
                date: new UntypedFormControl(answer, [Validators.required]),
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                date: new UntypedFormControl(answer),
            });
        }
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        if (this.data?.question?.optionList[0]) {
            this.data.question.optionList[0].answer = this.formatDate(this.formGroup?.get('date')?.value);
        } else {
            this.data.question.optionList = [
                new DsformModelQuestionOption({
                    answer: this.formatDate(this.formGroup?.get('date')?.value),
                }),
            ];
        }
        return this.data?.question;
    }

    private formatDate(selectedDate: Date) {
        if (!selectedDate) {
            return '';
        }
        // lack of leading zeros breaks the format. in their timezone but in UTC format
        const date = ('0' + selectedDate.getDate()).slice(-2);
        const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
        return `${selectedDate.getFullYear()}-${month}-${date}`;
    }

    private validate() {
        const answer = this.formGroup?.get('date')?.value;
        if (answer) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }
}
