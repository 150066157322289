import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable()
export class DeviceService {
    constructor(private platform: Platform) {}
    public isMobile() {
        return this.platform.ANDROID || this.platform.IOS;
    }
}
