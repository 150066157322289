import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DsformElementData, DsformModelQuestion } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-paragraph',
    templateUrl: './dsforms-paragraph.component.html',
    styleUrls: ['./dsforms-paragraph.component.scss'],
})
export class DsformsParagraphComponent implements DynamicElementComponent {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    private _paragraphHtml: SafeHtml;

    public get paragraphHtml(): SafeHtml {
        if (!this._paragraphHtml) {
            this._paragraphHtml =
                this.data?.question && this.data.question.question
                    ? this.sanitized.bypassSecurityTrustHtml(this.data.question.question)
                    : 'Paragraph';
        }
        return this._paragraphHtml;
    }

    constructor(private sanitized: DomSanitizer) {}

    public generateModel(): DsformModelQuestion {
        return this.data?.question;
    }
}
