import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[dsfDragAndDrop]',
})
export class DragAndDropDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() fileDropped = new EventEmitter<FileList>();
    @Output() overDropZone = new EventEmitter<boolean>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();

        this.fileOver = true;
        this.overDropZone.emit(true);
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();

        this.fileOver = false;
        this.overDropZone.emit(false);
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(event) {
        event.preventDefault();
        event.stopPropagation();

        this.fileOver = false;
        this.overDropZone.emit(false);
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
