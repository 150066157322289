import { Component, Input } from '@angular/core';
import { DsformElementData, DsformModelQuestion } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-separator',
    templateUrl: './dsforms-separator.component.html',
    styleUrls: ['./dsforms-separator.component.scss'],
})
export class DsformsSeparatorComponent implements DynamicElementComponent {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;

    constructor() {}

    public generateModel(): DsformModelQuestion {
        return this.data?.question;
    }
}
