import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DsformModel, ShortCodeModel } from '../dsform.model';
import { DsformService } from '../dsform.service';
import { DisplayChoice } from '../dsforms-content/populate-form/display-choice.enum';
import { FORM_ALREADY_SUBMITTED_MESSAGE, FORM_JUST_SUBMITTED_MESSAGE } from '../dsforms-content/populate-form/submitted-form-messages';

const DEFAULT_THEME_COLOR = '#d83a4b';

@Component({
    selector: 'dsf-dsforms-shortcode-layout',
    templateUrl: './dsforms-shortcode-layout.component.html',
    styleUrls: ['./dsforms-shortcode-layout.component.scss'],
})
export class DsformsShortcodeLayoutComponent {
    public forms: DsformModel[] = [];
    public shortCode = '';
    private formIndex = 0;
    public formToPopulate: DsformModel;
    public formNotFound = false;
    public displayChoice = DisplayChoice.ReadWrite;
    public messageToDisplay: string;
    public isSaving = false;

    constructor(private route: ActivatedRoute, private dsformService: DsformService, private dialog: MatDialog) {
        this.shortCode = this.route.snapshot.params.shortCode;
        // call dsform service to get forms for client to fill
        // then loop through all unsubmitted forms to pass to populate form
        this.dsformService
            .getFormsByShortCode(this.shortCode)
            .pipe(take(1))
            .subscribe((response: ApiResponseModel<ShortCodeModel>) => {
                if (response.error) {
                    this.formToPopulate = new DsformModel({ themeColor: DEFAULT_THEME_COLOR });
                    this.formNotFound = true;
                } else {
                    // filter out only unsubmitted forms associated with shortcode
                    this.forms = response.data.shortCodeFormList.filter((scf) => !scf.dateTimeSubmitted).map((scf) => scf.formTemplate);

                    // if all submitted, set formToPopulate to first one for theme color, header, etc.., but send down forms
                    // already submitted boolean
                    if (!this.forms.length) {
                        this.formToPopulate = response.data.shortCodeFormList[0].formTemplate;
                        this.displayChoice = DisplayChoice.MessageOnly;
                        this.messageToDisplay = FORM_ALREADY_SUBMITTED_MESSAGE;
                    } else {
                        this.displayChoice = DisplayChoice.ReadWrite;
                        this.formToPopulate = this.forms[this.formIndex++];
                    }
                }
            });
    }

    public formSubmit(comp: any) {
        if (this.formIndex < this.forms.length) {
            this.displayChoice = DisplayChoice.ReadWrite;
            this.formToPopulate = this.forms[this.formIndex++];
        } else {
            this.displayChoice = DisplayChoice.MessageOnly;
            this.messageToDisplay = FORM_JUST_SUBMITTED_MESSAGE;
        }
    }

    public onSave(formToSubmit: DsformModel) {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        const userFormToSubmit = new DsformModel({
            ...formToSubmit,

            questions: formToSubmit.questions.filter((q) => q.id),
        });
        this.dsformService
            .submitUserForm(userFormToSubmit, this.shortCode)
            .toPromise()
            .then((response: ApiResponseModel<DsformModel>) => {
                this.isSaving = false;
                if (response.error) {
                    this.dialog
                        .open(DialogComponent, {
                            width: '500px',
                            panelClass: 'error-dialog',
                            data: {
                                themeColor: this.formToPopulate?.themeColor,
                                title: 'Error',
                                content: response.statusMessage || 'Unable to save the form. Please try again.',
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    return;
                }
                if (this.formIndex < this.forms.length) {
                    this.displayChoice = DisplayChoice.ReadWrite;
                    this.formToPopulate = this.forms[this.formIndex++];
                } else {
                    this.displayChoice = DisplayChoice.MessageOnly;
                    this.messageToDisplay = FORM_JUST_SUBMITTED_MESSAGE;
                }
            });
    }
}
