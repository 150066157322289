import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DsformsFormLayoutComponent } from './pages/dsforms/dsforms-form/dsforms-form-layout.component';
import { DsformsPreviewLayoutComponent } from './pages/dsforms/dsforms-preview-form/dsforms-preview-layout.component';
import { DsformsShortcodeLayoutComponent } from './pages/dsforms/dsforms-shortcode/dsforms-shortcode-layout.component';

const routes: Routes = [
    {
        path: '',
        component: DsformsPreviewLayoutComponent,
        data: {},
    },
    {
        path: 'Preview',
        component: DsformsPreviewLayoutComponent,
        data: {},
    },
    {
        path: ':shortCode',
        component: DsformsShortcodeLayoutComponent,
        data: {
            type: 'clientForm',
        },
    },
    {
        path: 'Form/:ulid',
        component: DsformsFormLayoutComponent,
        data: {
            type: 'clientForm',
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
