<div class="dsforms-populate-form-container">
    <ng-container *ngIf="formNotFound">
        <div class="form-not-found-container">
            <div class="not-found-img-container">
                <img src="../../../../assets/images/error-404.svg" class="form-not-found-img" />
            </div>
            <div class="form-not-found-details">The form you are looking for couldn't be found</div>
        </div>
    </ng-container>
    <form *ngIf="!formNotFound" [formGroup]="dsform">
        <div class="form-header-container">
            <div *ngIf="!formToPopulate.logoUrl || isLoading()" class="form-icon-container" [style.background]="formToPopulate.themeColor">
                <img
                    src="../../../../../assets/images/google-docs.png"
                    srcset="assets/images/google-docs@2x.png 2x, assets/images/google-docs@3x.png 3x"
                    class="google-docs"
                />
            </div>
            <div *ngIf="formToPopulate.logoUrl && !isLoading()" class="form-icon-container form-logo-container">
                <img *ngIf="!formToPopulate.logoUrl['Base64']" [src]="formToPopulate.logoUrl" />
                <img *ngIf="formToPopulate.logoUrl['Base64']" [src]="formToPopulate.logoUrl['Base64']" />
            </div>
            <div class="business-name-location-container" *ngIf="!isLoading()">
                <div id="business-name" qa="form-business-name">
                    {{ formToPopulate.header }}
                </div>
                <div id="business-location" [style.color]="formToPopulate.themeColor" qa="form-subheadding">
                    {{ formToPopulate.subheader }}
                </div>
            </div>
            <div class="business-name-location-container loading" *ngIf="isLoading()">
                <div id="business-name" qa="form-business-name"></div>
                <div id="business-location" qa="form-subheadding"></div>
            </div>
        </div>
        <div *ngIf="!isMessageOnly()" [class.readonly]="isReadOnly()">
            <div class="blank-form-presets-container" *ngIf="!isLoading()">
                <div id="form-title" qa="form-title">
                    {{ formToPopulate.title }}
                </div>
            </div>
            <div class="blank-form-presets-container loading" *ngIf="isLoading()">
                <div id="form-title" qa="form-title"></div>
            </div>

            <div class="form-main-container">
                <div class="form-elements-list-container">
                    <ng-container *ngIf="!isLoading()">
                        <dsf-dsforms-populate-form-element
                            *ngFor="let element of formElements"
                            [element]="element"
                            [themeColor]="formToPopulate.themeColor"
                        >
                        </dsf-dsforms-populate-form-element>
                    </ng-container>
                    <ng-container *ngIf="isLoading()">
                        <div *ngFor="let element of formElements" class="form-element-skeleton"></div>
                    </ng-container>
                </div>
            </div>
            <div class="form-submit-button" *ngIf="isSubmitShown()">
                <button
                    [disabled]="isReadOnly() || isPreview()"
                    [style.background]="formToPopulate.themeColor"
                    type="button"
                    mat-flat-button
                    (click)="submitForm()"
                    qa="button-form-submit"
                >
                    <div *ngIf="!isSaving">Submit</div>
                    <div *ngIf="isSaving" class="submitting-spinner">
                        <mat-spinner [diameter]="25"></mat-spinner>
                    </div>
                </button>
            </div>
        </div>
        <div class="form-complete-container" *ngIf="isMessageOnly()">
            <div class="icon-container">
                <i class="material-icons dsform-complete-icon" [style.color]="formToPopulate.themeColor">check_circle</i>
            </div>
            <div class="form-complete-details" qa="form-complete-message">
                {{ messageToDisplay }}
            </div>
        </div>
    </form>
</div>
