import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DsformElementData, DsformModelQuestion } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-header',
    templateUrl: './dsforms-header.component.html',
    styleUrls: ['./dsforms-header.component.scss'],
})
export class DsformsHeaderComponent implements DynamicElementComponent {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public get headerHtml(): SafeHtml {
        return this.data?.question ? this.sanitized.bypassSecurityTrustHtml(this.data.question.question) : 'Header';
    }

    constructor(private sanitized: DomSanitizer) {}

    public generateModel(): DsformModelQuestion {
        return this.data?.question;
    }
}
