<div
    class="element-form-container dsforms-element-scale-container"
    [ngClass]="{ disabled: disabled }"
    formGroupName="{{ formGroupName }}"
    qa="scale-element"
>
    <div class="scale-question element-question-static">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div
        class="scale-rating-preview-container"
        [ngClass]="{
            'ten-icons-preview-container': ratingType === scaleRatingType.ScaleRating10,
            'scale-icon-preview-container': ratingType !== scaleRatingType.StarRating
        }"
    >
        <div class="rating-lables">
            <span class="scale-label-wrapper" qa="scale-start-label">{{ startLabel }}</span>
            <span class="scale-label-wrapper" qa="scale-end-label">{{ endLabel }}</span>
        </div>
        <div class="scale-icons-wrapper" [ngClass]="{ 'ten-icons-container': ratingType === scaleRatingType.ScaleRating10 }">
            <!-- <span qa="scale-start-label" *ngIf="ratingType !== scaleRatingType.ScaleRating10">{{ startLabel }}</span> -->
            <div
                class="icon-container"
                *ngFor="
                    let pos of [].constructor(
                        ratingType === scaleRatingType.StarRating || ratingType === scaleRatingType.ScaleRating5 ? 5 : 10
                    );
                    let i = index
                "
            >
                <ng-container *ngIf="ratingType === scaleRatingType.StarRating">
                    <i
                        class="material-icons dsforms-scale-rating-icon"
                        *ngIf="!rated() || !withRatedNumber(i + 1)"
                        (click)="rating(i + 1)"
                        qa="star-{{ i + 1 }}"
                    >
                        star_border
                    </i>
                    <i
                        class="material-icons dsforms-scale-rating-icon"
                        *ngIf="rated() && withRatedNumber(i + 1)"
                        [style.color]="themeColor"
                        (click)="rating(i + 1)"
                        >star</i
                    >
                </ng-container>
                <ng-container *ngIf="ratingType !== scaleRatingType.StarRating">
                    <div class="rating-value">{{ i + 1 }}</div>
                    <i
                        [ngClass]="{ 'ten-icons': ratingType === scaleRatingType.ScaleRating10 }"
                        class="material-icons dsforms-scale-rating-icon scale-icon"
                        *ngIf="!rated() || !sameAsRatedNumber(i + 1)"
                        (click)="rating(i + 1)"
                        >radio_button_unchecked</i
                    >
                    <i
                        [ngClass]="{ 'ten-icons': ratingType === scaleRatingType.ScaleRating10 }"
                        class="material-icons dsforms-scale-rating-icon scale-icon"
                        *ngIf="rated() && sameAsRatedNumber(i + 1)"
                        [style.color]="themeColor"
                        (click)="rating(i + 1)"
                        >radio_button_checked</i
                    >
                </ng-container>
            </div>
            <!-- <span qa="scale-end-label" *ngIf="ratingType !== scaleRatingType.ScaleRating10">{{ endLabel }}</span> -->
        </div>
    </div>
    <mat-error qa="error" *ngIf="hasError">This is required. </mat-error>
</div>
