<div class="element-form-container dsforms-element-time-container" formGroupName="{{ formGroupName }}" qa="time-element">
    <div class="time-question element-question-static" qa="time-question">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <div class="time-picker-container">
        <mat-form-field floatLabel="never">
            <i class="material-icons">access_time</i> <input type="time" id="input" class="form-control" formControlName="time" matInput />
        </mat-form-field>
        <mat-error qa="error" *ngIf="hasError">Time is required. </mat-error>
    </div>
</div>
