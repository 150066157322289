export enum ErrorCode {
    // corresponds to status codes
    NoResourceAvailable = 4084,
    ServiceLimitationError = 4001,
    ResourceInUse = 10032,
    GiftCardCannotBeDeleted = 1706,
    ExpenseCategoryCannotBeDeleted = 10018,
    InvalidParameterException = 10001,
    TipWithTicketNotClosedOrVoidedException = 10036,
}

export class ApiResponseModel<T> {
    /** Data transformed into frontend model */
    data: T;
    /** Whether an error happened during an api call */
    error: boolean;
    /** Self defined error data */
    errorData?: any;
    /** Backend error code */
    errorCode?: ErrorCode;
    /** Backend error message */
    statusMessage?: string;
    /** HTTP Status Code */
    httpStatus?: number;

    constructor(params: Partial<ApiResponseModel<T>>) {
        for (const field of Object.keys(params)) {
            this[field] = params[field];
        }
    }

    get isHttp401Error(): boolean {
        return this.httpStatus === 401;
    }

    get isHttp500Error(): boolean {
        return this.httpStatus === 500;
    }

    get success() {
        return !this.error;
    }
}
