<div *ngIf="formToPopulate || formNotFound" class="dsforms-shortcode-layout-container">
    <dsf-dsforms-content-layout
        [formToPopulate]="formToPopulate"
        [formNotFound]="formNotFound"
        [displayChoice]="displayChoice"
        [messageToDisplay]="messageToDisplay"
        [isSaving]="isSaving"
        (save)="onSave($event)"
    ></dsf-dsforms-content-layout>
</div>
