import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { DeviceService } from 'src/app/services/device.service';
import { DynamicElementComponent } from '../populate-form-element.component';

export const REMINDERS_AND_PROMOTIONS = 'remindersAndPromotions';
export const BIRTHDAY = 'birthday';

export enum ReceiveRemindersBy {
    None,
    EmailAndtText,
    EmailOnly,
    TextOnly,
}

export enum ReceivePromotionsBy {
    None,
    EmailAndtText,
    EmailOnly,
    TextOnly,
}

@Component({
    selector: 'dsf-dsforms-client-info',
    templateUrl: './dsforms-client-info.component.html',
    styleUrls: ['./dsforms-client-info.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsClientInfoComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public options: DsformModelQuestionOption[];
    public hasError = false;
    public monthOptions = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    public dayOptions: number[] = [];
    public yearOptions: number[] = [];
    public receiveRemindersBy = ReceiveRemindersBy;
    public receivePromotionsBy = ReceivePromotionsBy;

    public get yearValue(): number {
        return this.formGroup?.get(BIRTHDAY)?.get('year')?.value;
    }
    public set yearValue(year: number) {
        this.formGroup?.get(BIRTHDAY)?.get('year')?.setValue(year);
        // February:  this.monthsTest[1]
        this.yearValueChange();
    }
    public yearValueChange() {
        if (this.monthValue === this.monthOptions[1]) {
            this.generateDayOptions(2);
        }
    }
    public get monthValue(): string {
        return this.formGroup?.get(BIRTHDAY)?.get('month')?.value;
    }
    public set monthValue(monthName: string) {
        this.formGroup?.get(BIRTHDAY)?.get('month')?.setValue(monthName);
        this.monthValueChange();
    }
    public monthValueChange() {
        this.generateDayOptions(this.monthOptions.indexOf(this.monthValue) + 1);
    }
    public get dayValue(): number {
        return this.formGroup?.get(BIRTHDAY)?.get('day')?.value;
    }
    public set dayValue(i: number) {
        this.formGroup?.get(BIRTHDAY)?.get('day')?.setValue(i);
    }
    public get reminderValue(): ReceiveRemindersBy {
        const val = this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('reminders')?.value;
        return val ? val : ReceiveRemindersBy.None;
    }
    public set reminderValue(value: ReceiveRemindersBy) {
        this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('reminders')?.setValue(value);
    }
    public get promotionValue(): ReceivePromotionsBy {
        const val = this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('promotions')?.value;
        return val ? val : ReceivePromotionsBy.None;
    }
    public set promotionValue(value: ReceivePromotionsBy) {
        this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('promotions')?.setValue(value);
    }
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }
    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    constructor(private elementForm: FormGroupDirective, public deviceService: DeviceService) {}

    ngOnInit(): void {
        this.formGroupName = `${this.data?.question?.id}`;
        this.formGroup = new UntypedFormGroup({});
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);
        this.generateBirthdaySelections();
        if (this.data) {
            this.options = this.data?.question?.optionList;
            this.options?.forEach((option: DsformModelQuestionOption) => {
                if (option.type !== REMINDERS_AND_PROMOTIONS && option.type !== BIRTHDAY) {
                    let formCtrl: UntypedFormControl;
                    if (this.data?.question?.required) {
                        formCtrl = new UntypedFormControl('', [Validators.required]);
                    } else {
                        formCtrl = new UntypedFormControl('');
                    }

                    if (option.answer) {
                        formCtrl = new UntypedFormControl(option.answer);
                    }
                    this.formGroup.addControl(option.type, formCtrl);
                } else if (option.type === REMINDERS_AND_PROMOTIONS) {
                    let remindersGroup: UntypedFormGroup;
                    if (this.data?.question?.required) {
                        remindersGroup = new UntypedFormGroup({
                            reminders: new UntypedFormControl('', [Validators.required]),
                            promotions: new UntypedFormControl('', [Validators.required]),
                        });
                    } else {
                        remindersGroup = new UntypedFormGroup({
                            reminders: new UntypedFormControl(''),
                            promotions: new UntypedFormControl(''),
                        });
                    }

                    if (option.answer) {
                        const answerString = option.answer as string;
                        const answerArr = answerString.split(',');
                        const reminders = parseInt(answerArr[0]);
                        const promotions = parseInt(answerArr[1]);

                        remindersGroup = new UntypedFormGroup({
                            reminders: new UntypedFormControl(reminders as ReceiveRemindersBy),
                            promotions: new UntypedFormControl(promotions as ReceiveRemindersBy),
                        });
                    }
                    this.formGroup.addControl(REMINDERS_AND_PROMOTIONS, remindersGroup);
                } else if (option.type === BIRTHDAY) {
                    let birthdayGroup: UntypedFormGroup;
                    if (this.data?.question?.required) {
                        birthdayGroup = new UntypedFormGroup({
                            year: new UntypedFormControl(''),
                            month: new UntypedFormControl('', [Validators.required]),
                            day: new UntypedFormControl('', [Validators.required]),
                        });
                    } else {
                        birthdayGroup = new UntypedFormGroup({
                            year: new UntypedFormControl(''),
                            month: new UntypedFormControl(''),
                            day: new UntypedFormControl(''),
                        });
                    }

                    if (option.answer) {
                        const answerString = option.answer as string;
                        const answerArr = answerString.split(',');
                        this.generateDayOptions(parseInt(answerArr[1]));

                        birthdayGroup = new UntypedFormGroup({
                            year: new UntypedFormControl(parseInt(answerArr[2])),
                            day: new UntypedFormControl(parseInt(answerArr[1])),
                            month: new UntypedFormControl(this.monthOptions[parseInt(answerArr[0]) - 1]),
                        });
                    }
                    this.formGroup.addControl(BIRTHDAY, birthdayGroup);
                }
            });
        }
    }

    private generateBirthdaySelections(): void {
        const currentYear = new Date().getFullYear();
        let yearToInsert = currentYear;

        while (yearToInsert >= currentYear - 100) {
            this.yearOptions.push(yearToInsert--);
        }
    }

    private generateDayOptions(monthNumber: number): void {
        this.dayOptions = [];
        let days = 31;
        const MonthWith30days = [4, 6, 9, 11];
        if (monthNumber === 2) {
            const isLeapYear = (this.yearValue % 4 === 0 && this.yearValue % 100 !== 0) || this.yearValue % 400 === 0;
            days = isLeapYear ? 29 : 28;
        } else if (MonthWith30days.includes(monthNumber)) {
            days = 30;
        }
        for (let j = 1; j <= days; j++) {
            this.dayOptions.push(j);
        }
    }

    public generateModel(): DsformModelQuestion {
        this.getAllAnswers();
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        return this.data?.question;
    }

    private validate() {
        let allFilled = true;
        this.data?.question?.optionList.forEach((o: any) => {
            if (!o.answer) {
                allFilled = false;
            }
        });
        if (allFilled) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }

    private getAllAnswers(): DsformModelQuestionOption[] {
        this.data?.question?.optionList.forEach((o: any) => {
            if (o.type !== REMINDERS_AND_PROMOTIONS && o.type !== BIRTHDAY) {
                o.answer = this.formGroup?.get(o.type)?.value;
            } else if (o.type === REMINDERS_AND_PROMOTIONS) {
                const remindersValue = this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('reminders')?.value;
                const promotionsValue = this.formGroup?.get(REMINDERS_AND_PROMOTIONS)?.get('promotions')?.value;

                const hasValue =
                    remindersValue !== undefined && remindersValue !== null && promotionsValue !== undefined && promotionsValue !== null;

                o.answer = hasValue ? remindersValue + ',' + promotionsValue : '';
            } else if (o.type === BIRTHDAY) {
                const year = this.formGroup?.get(BIRTHDAY)?.get('year')?.value;

                const answers =
                    this.formGroup?.get(BIRTHDAY)?.get('day')?.value && this.formGroup?.get(BIRTHDAY)?.get('month')?.value
                        ? this.monthOptions.indexOf(this.formGroup?.get(BIRTHDAY)?.get('month')?.value) +
                          1 +
                          ',' +
                          this.formGroup?.get(BIRTHDAY)?.get('day')?.value +
                          ',' +
                          (year !== '' ? year : '1896')
                        : '';
                o.answer = answers;
            }
        });
        return this.data?.question?.optionList;
    }
}
