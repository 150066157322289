<div class="dsforms-content-layout-container">
    <div class="dsforms-content-container">
        <div class="dsforms-content-body-top-red-background" [style.background]="this.formToPopulate.themeColor"></div>
        <div class="dsforms-content-body-container">
            <dsf-populate-form
                [formToPopulate]="formToPopulate"
                [formNotFound]="formNotFound"
                [displayChoice]="displayChoice"
                [messageToDisplay]="messageToDisplay"
                [isSaving]="isSaving"
                (save)="onSave($event)"
            ></dsf-populate-form>
        </div>
    </div>
</div>
