export class BaseModel<T> {
    constructor(params: Partial<T>, opts: { allowNull: boolean } = { allowNull: false }) {
        for (const field of Object.keys(params)) {
            if (opts.allowNull) {
                this[field] = params[field];
            } else {
                // Only ignore undefined and null fields
                if (params[field] !== undefined && params[field] !== null) {
                    this[field] = params[field];
                }
            }
        }
    }
}
