import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DsformApiService, GetSignedUrlResponse } from 'src/app/services/api/dsform/dsform-api.service';
import {
    DsformElementType,
    DsformModel,
    DsformModelQuestion,
    DsformModelQuestionOption,
    FileUploadType,
    ShortCodeModel,
} from './dsform.model';

export enum FileRawType {
    Signature = 'rawSignature',
    FileUpload = 'rawFileUpload',
}

export enum DsformFileUploadType {
    Logo = 1,
    Image = 2,
    Signature = 3,
    File = 4,
}

interface OptionForkJoinResultsDictionary<T> {
    [optionListIdx: string]: ApiResponseModel<T>;
}

interface OptionForkJoinObservablesDictionary<T> {
    [optionListIdx: string]: Observable<ApiResponseModel<T>>;
}

interface ElementForkJoinResultsDictionary<T> {
    [elementId: string]: ApiResponseModel<T> | OptionForkJoinResultsDictionary<T>;
}

interface ElementForkJoinObservablesDictionary<T> {
    [elementId: string]: Observable<ApiResponseModel<T>> | Observable<OptionForkJoinResultsDictionary<T>>;
}

@Injectable()
export class DsformService {
    constructor(private dsFormApiService: DsformApiService) {}

    public getFormsByShortCode(shortcode: string): Observable<ApiResponseModel<ShortCodeModel>> {
        return this.dsFormApiService.getFormsByShortCode(shortcode);
    }

    public getFormTemplateByUlid(formTemplateUlid: string): Observable<ApiResponseModel<DsformModel>> {
        return this.dsFormApiService.getFormTemplateByUlid(formTemplateUlid);
    }

    public submitUserForm(form: DsformModel, shortCode: string): Observable<ApiResponseModel<DsformModel>> {
        const signatureOrFileUploadElements = form.questions?.filter(
            (q) => q.type === DsformElementType.Signature || q.type === DsformElementType.FileUpload
        );
        if (signatureOrFileUploadElements && signatureOrFileUploadElements.length > 0) {
            return this.handleFormWithSignatureOrFileUploadElements(form, shortCode);
        } else {
            return this.dsFormApiService.submitUserForm(form, shortCode);
        }
    }

    private handleFormWithSignatureOrFileUploadElements(form: DsformModel, shortCode: string): Observable<ApiResponseModel<DsformModel>> {
        // make a copy of the form we can modify the options for, since the format of signature/file upload options passed in
        // is not what we actually want to save to the server
        const updatedForm = new DsformModel({
            ...form,
            questions: form.questions.map(
                (q) =>
                    new DsformModelQuestion({
                        ...q,
                        optionList: q.optionList?.map(
                            (o) =>
                                new DsformModelQuestionOption({
                                    ...o,
                                })
                        ),
                    })
            ),
        });
        const signatureElements = updatedForm.questions.filter((q) => q.type === DsformElementType.Signature);
        const fileUploadElements = updatedForm.questions.filter((q) => q.type === DsformElementType.FileUpload);

        // the elementGetSignedUrlObservablesDict is used with forkJoin().  forkJoin can take a "dictionary" - in this
        // case an object where the properties are the keys of the dictionary, and the values of those properties are
        // observables.  forkJoin will wait until all the observables in the dictionary have completed, then emit
        // a "dictionary"(object) where the "keys"(properties) are the same as the input dictionary, but the values
        // are the results of each respective observable.
        // for Signature elements, which can only have one file to upload as part of their optionList, the observable
        // in the dictionary is a single observable operation (either the getSignedUrl or the call to upload the file).
        // for FileUpload element, which can have multiple files to upload in their optionList, the observable in the
        // dictionary is a nested forkJoin object, where the "keys" of that "dictionary" are the index of the option
        // in the element's optionList
        // exampleElementObservablesDict = {
        //     'SignatureElementUlid_1': Observable<ApiResponseModel<GetSignedUrlResponse>>,
        //     'SignatureElementUlid_2': Observable<ApiResponseModel<GetSignedUrlResponse>>,
        //     'FileUploadElementUlid_1': forkJoin({
        //         '0': Observable<ApiResponseModel<GetSignedUrlResponse>>,
        //         '1': Observable<ApiResponseModel<GetSignedUrlResponse>>
        //     })
        // }
        // when the above example is passed to forkJoin, it would result in an observable like this:
        // forkJoin(exampleElementObservablesDict) results in this - Observable<{
        //     'SignatureElementUlid_1': ApiResponseModel<GetSignedUrlResponse>,
        //     'SignatureElementUlid_2': ApiResponseModel<GetSignedUrlResponse>,
        //     'FileUploadElementUlid_1': {
        //         '0': ApiResponseModel<GetSignedUrlResponse>,
        //         '1': ApiResponseModel<GetSignedUrlResponse>
        //     }
        // }>
        const elementGetSignedUrlObservablesDict: ElementForkJoinObservablesDictionary<GetSignedUrlResponse> = {};
        signatureElements.forEach((signatureElement) => {
            const rawSignatureOption = signatureElement.optionList?.find((o) => o.type === FileRawType.Signature);
            if (!rawSignatureOption) {
                // the signature was not provided correctly, submit it to the server blank.  if required, it'll be rejected
                signatureElement.optionList = [];
                return;
            }
            const rawUrl = rawSignatureOption.answer;
            if (!rawUrl) {
                // there is no base64 string to use for the signature, nothing to upload
                signatureElement.optionList = [];
                return;
            }
            let filename = rawSignatureOption.value;
            if (!filename) {
                filename = `signature_${signatureElement.id}.jpg`;
            }
            elementGetSignedUrlObservablesDict[`${signatureElement.id}`] = this.dsFormApiService.getSignedUrl(
                filename,
                FileUploadType.Signature,
                updatedForm.id || ''
            );
        });
        fileUploadElements.forEach((fileUploadElement) => {
            // file upload allows for multiple files
            const rawFileUploadOptions = fileUploadElement.optionList?.filter((o) => o.type === FileRawType.FileUpload);
            if (!rawFileUploadOptions) {
                // the file(s) were not provided correctly, submit it to the server blank.  if required, it'll be rejected
                fileUploadElement.optionList = [];
                return;
            }
            const optionGetSignedUrlObservablesDict: OptionForkJoinObservablesDictionary<GetSignedUrlResponse> = {};
            rawFileUploadOptions.forEach((rawFileUploadOption, idx) => {
                const rawUrl = rawFileUploadOption.answer;
                if (!rawUrl) {
                    // there is no base64 string to use for the file(s), nothing to upload
                    fileUploadElement.optionList = [];
                    return;
                }
                let filename = rawFileUploadOption.value;
                if (!filename) {
                    filename = `file_${fileUploadElement.id}_${idx}.unknown`; // or should we error?
                }
                optionGetSignedUrlObservablesDict[`${idx}`] = this.dsFormApiService.getSignedUrl(
                    filename,
                    FileUploadType.File,
                    updatedForm.id || ''
                );
            });
            if (Object.getOwnPropertyNames(optionGetSignedUrlObservablesDict).length === 0) {
                // no observables to queue for this file upload element
                return;
            }
            elementGetSignedUrlObservablesDict[`${fileUploadElement.id}`] = forkJoin(optionGetSignedUrlObservablesDict);
        });
        if (Object.getOwnPropertyNames(elementGetSignedUrlObservablesDict).length === 0) {
            // no observables were queued
            return this.dsFormApiService.submitUserForm(updatedForm, shortCode);
        }
        return forkJoin(elementGetSignedUrlObservablesDict).pipe(
            switchMap((elementGetSignedUrlResultsDict: ElementForkJoinResultsDictionary<GetSignedUrlResponse>) => {
                return this.handleGetSignedUrlResponses(updatedForm, shortCode, elementGetSignedUrlResultsDict);
            })
        );
    }

    private handleGetSignedUrlResponses(
        form: DsformModel,
        shortCode: string,
        elementGetSignedUrlResultsDict: ElementForkJoinResultsDictionary<GetSignedUrlResponse>
    ): Observable<ApiResponseModel<DsformModel>> {
        if (!elementGetSignedUrlResultsDict) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error getting signed urls - missing elementGetSignedUrlResultsDict',
                })
            );
        }
        const elementIds = Object.getOwnPropertyNames(elementGetSignedUrlResultsDict);
        if (!elementIds || elementIds.length === 0) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error getting signed urls - empty elementGetSignedUrlResultsDict',
                })
            );
        }
        const elementFileUploadObservablesDict: ElementForkJoinObservablesDictionary<boolean> = {};
        elementIds.forEach((elementId) => {
            const targetElement = form.questions.find((e) => e.id === elementId);
            if (!targetElement) {
                elementFileUploadObservablesDict[`${elementId}`] = of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: `Error saving aws file key to question - could not find question with id ${elementId}`,
                    })
                );
                return;
            }
            if (targetElement.type === DsformElementType.Signature) {
                const getSignedUrlResponse = elementGetSignedUrlResultsDict[elementId] as ApiResponseModel<GetSignedUrlResponse>;
                elementFileUploadObservablesDict[`${elementId}`] = this.handleGetSignedUrlResponseForSignature(
                    targetElement,
                    getSignedUrlResponse
                );
            } else if (targetElement.type === DsformElementType.FileUpload) {
                const optionGetSignedUrlResultsDict = elementGetSignedUrlResultsDict[
                    elementId
                ] as OptionForkJoinResultsDictionary<GetSignedUrlResponse>;
                elementFileUploadObservablesDict[`${elementId}`] = this.handleGetSignedUrlResponseForFileUpload(
                    targetElement,
                    optionGetSignedUrlResultsDict
                );
            } else {
                elementFileUploadObservablesDict[`${elementId}`] = of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: `Error saving aws file key to question - Types other than 'signature' unsupported at this time.`,
                    })
                );
            }
        });
        return forkJoin(elementFileUploadObservablesDict).pipe(
            switchMap((elementFileUploadResultsDict: ElementForkJoinResultsDictionary<boolean>) => {
                return this.handleFileUploadResult(form, shortCode, elementFileUploadResultsDict);
            })
        );
    }

    private handleGetSignedUrlResponseForSignature(
        targetElement: DsformModelQuestion,
        getSignedUrlResponse: ApiResponseModel<GetSignedUrlResponse>
    ): Observable<ApiResponseModel<boolean>> {
        if (getSignedUrlResponse.error) {
            return of(
                new ApiResponseModel<boolean>({
                    error: true,
                    statusMessage: getSignedUrlResponse.statusMessage || 'Error getting signed urls - error response',
                })
            );
        }
        const targetOption = targetElement.optionList?.find((o) => o.type === FileRawType.Signature);
        if (!targetOption) {
            return of(
                new ApiResponseModel<boolean>({
                    error: true,
                    statusMessage: `Error saving aws file key to question - could not find '${FileRawType.Signature}' option for question with key ${targetElement.id}`,
                })
            );
        }
        const dataUrl = targetOption.answer;
        targetElement.optionList = [
            new DsformModelQuestionOption({
                ...targetElement.optionList[0],
                type: undefined,
                value: undefined,
                answer: getSignedUrlResponse.data.Key,
            }),
        ];
        return this.dsFormApiService.upload(getSignedUrlResponse.data.SignedUrl, this.dataURLToBlob(dataUrl));
    }

    private handleGetSignedUrlResponseForFileUpload(
        targetElement: DsformModelQuestion,
        optionGetSignedUrlResultsDict: OptionForkJoinResultsDictionary<GetSignedUrlResponse>
    ): Observable<{ [index: string]: ApiResponseModel<boolean> }> {
        if (!optionGetSignedUrlResultsDict) {
            return forkJoin({
                '0': of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: 'Error getting signed urls - missing optionGetSignedUrlResultsDict',
                    })
                ),
            });
        }
        const optionIndicies = Object.getOwnPropertyNames(optionGetSignedUrlResultsDict);
        if (!optionIndicies || optionIndicies.length === 0) {
            return forkJoin({
                '0': of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: 'Error getting signed urls - empty optionGetSignedUrlResultsDict',
                    })
                ),
            });
        }
        const optionFileUploadObservablesDict: OptionForkJoinObservablesDictionary<boolean> = {};
        optionIndicies.forEach((index) => {
            const indexNum = parseInt(index);
            const getSignedUrlResponse = optionGetSignedUrlResultsDict[index];
            if (getSignedUrlResponse.error) {
                optionFileUploadObservablesDict[`${index}`] = of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: getSignedUrlResponse.statusMessage || 'Error getting signed urls - error response',
                    })
                );
                return;
            }
            const targetOption = targetElement.optionList?.find((o, idx) => o.type === FileRawType.FileUpload && idx === indexNum);
            if (!targetOption) {
                optionFileUploadObservablesDict[`${index}`] = of(
                    new ApiResponseModel<boolean>({
                        error: true,
                        statusMessage: `Error saving aws file key to question - could not find '${FileRawType.FileUpload}' option for question with index ${index}`,
                    })
                );
                return;
            }
            const dataUrl = targetOption.answer;
            targetElement.optionList.splice(
                indexNum,
                1,
                new DsformModelQuestionOption({
                    ...targetElement.optionList[indexNum],
                    type: undefined,
                    value: undefined,
                    answer: getSignedUrlResponse.data.Key,
                })
            );
            optionFileUploadObservablesDict[`${index}`] = this.dsFormApiService.upload(
                getSignedUrlResponse.data.SignedUrl,
                this.dataURLToBlob(dataUrl)
            );
        });
        return forkJoin(optionFileUploadObservablesDict);
    }

    private handleFileUploadResult(
        form: DsformModel,
        shortCode: string,
        elementFileUploadResultsDict: ElementForkJoinResultsDictionary<boolean>
    ): Observable<ApiResponseModel<DsformModel>> {
        if (!elementFileUploadResultsDict) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error uploading files - missing elementFileUploadResultsDict',
                })
            );
        }
        const elementIds = Object.getOwnPropertyNames(elementFileUploadResultsDict);
        if (!elementIds || elementIds.length === 0) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error uploading files - empty elementFileUploadResultsDict',
                })
            );
        }
        // while looping through all the elements that uploaded a file, returnVal will only
        // get set if something errored.  If there are no errors, returnVal will be undefined
        // after we finish looping through all elements, which will trigger the logic to submit
        // the user form (with the options of the elements that uploaded a file modified to
        // include the file aws key)
        let returnVal: Observable<ApiResponseModel<DsformModel>> | undefined = undefined;
        elementIds.forEach((elementId) => {
            const targetElement = form.questions.find((e) => e.id === elementId);
            if (!targetElement) {
                returnVal = of(
                    new ApiResponseModel<DsformModel>({
                        error: true,
                        statusMessage: `Error uploading files - element with id ${elementId} not found.`,
                    })
                );
                return;
            }
            if (targetElement.type === DsformElementType.Signature) {
                const uploadResult = elementFileUploadResultsDict[elementId] as ApiResponseModel<boolean>;
                returnVal = this.handleFileUploadResultForSignature(uploadResult);
            } else if (targetElement.type === DsformElementType.FileUpload) {
                const optionFileUploadResultsDict = elementFileUploadResultsDict[elementId] as OptionForkJoinResultsDictionary<boolean>;
                returnVal = this.handleFileUploadResultForFileUpload(optionFileUploadResultsDict);
            } else {
                returnVal = of(
                    new ApiResponseModel<DsformModel>({
                        error: true,
                        statusMessage: `Error uploading files - element types other than 'Signature' are not supported yet.`,
                    })
                );
                return;
            }
        });
        if (!returnVal) {
            returnVal = this.dsFormApiService.submitUserForm(form, shortCode);
        }
        return returnVal;
    }

    private handleFileUploadResultForSignature(
        uploadResult: ApiResponseModel<boolean>
    ): Observable<ApiResponseModel<DsformModel>> | undefined {
        if (!uploadResult || uploadResult.error || !uploadResult.data) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: uploadResult.statusMessage || 'Error uploading files - error response',
                })
            );
        }
        return undefined;
    }

    private handleFileUploadResultForFileUpload(
        optionFileUploadResultsDict: OptionForkJoinResultsDictionary<boolean>
    ): Observable<ApiResponseModel<DsformModel>> | undefined {
        if (!optionFileUploadResultsDict) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error uploading files - missing optionFileUploadResultsDict',
                })
            );
        }
        const optionIndicies = Object.getOwnPropertyNames(optionFileUploadResultsDict);
        if (!optionIndicies || optionIndicies.length === 0) {
            return of(
                new ApiResponseModel<DsformModel>({
                    error: true,
                    statusMessage: 'Error uploading files - empty optionFileUploadResultsDict',
                })
            );
        }
        let returnVal: Observable<ApiResponseModel<DsformModel>> | undefined = undefined;
        optionIndicies.forEach((index) => {
            const indexNum = parseInt(index);
            const uploadResult = optionFileUploadResultsDict[indexNum];
            if (!uploadResult || uploadResult.error || !uploadResult.data) {
                returnVal = of(
                    new ApiResponseModel<DsformModel>({
                        error: true,
                        statusMessage: uploadResult.statusMessage || 'Error uploading files - error response',
                    })
                );
            }
        });
        return returnVal;
    }

    private dataURLToBlob(dataURL: any) {
        // Code taken from https://github.com/ebidel/filer.js
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }
}
