import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DsformModel, FileUploadType, ShortCodeModel } from 'src/app/pages/dsforms/dsform.model';
import { ApiResponseHandlerService } from '../../api-response-handler.service';
import axios, { AxiosInstance } from 'axios';
import { EnvConfigLoaderService } from '../../env-config-loader.service';
import { take } from 'rxjs/operators';

export interface GetSignedUrlResponse {
    SignedUrl: string;
    Key: string;
}

@Injectable()
export class DsformApiService extends ApiResponseHandlerService {
    private __internalAxiosInstance: ReplaySubject<AxiosInstance>;
    private get _axiosInstance(): Observable<AxiosInstance> {
        if (!this.__internalAxiosInstance) {
            this.config.loadEnvConfig().subscribe(() => {
                const envConfig = this.config.getEnvConfig();
                this._onlineFormsServiceUrl = envConfig.onlineFormsServiceUrl?.replace(/\/$/, '') || '';
                this.__internalAxiosInstance = new ReplaySubject<AxiosInstance>(1);
                const newAxiosInstance = axios.create({
                    headers: {
                        'x-api-key': envConfig.onlineFormsServiceApiKey,
                    },
                });
                this.__internalAxiosInstance.next(newAxiosInstance);
                this.__internalAxiosInstance.complete();
            });
        }
        return this.__internalAxiosInstance.asObservable();
    }
    /**
     * _onlineFormsServiceUrl should be automatically populated by getting (and subbing to) _axiosInstance
     */
    private _onlineFormsServiceUrl: string;

    constructor(private config: EnvConfigLoaderService) {
        super();
    }

    public getFormsByShortCode(shortCode: string): Observable<ApiResponseModel<ShortCodeModel>> {
        const body = {
            query: `query getFormDataByShortCodeQuery {
                getFormsByShortCode(input:{
                    ShortCode:{
                        ID:\"${shortCode}\"
                    }
                }) {
                    ID,
                    ReferenceID,
                    CustomerID,
                    ShortCodeFormList {
                        DateTimeSubmitted,
                        FormTemplate {
                            DateTimeCreated,
                            DateTimeModified,
                            Header,
                            LogoUrl,
                            Name,
                            ResponseCount,
                            Status,
                            Subheader,
                            ThemeColor,
                            Title,
                            Ulid,
                            Questions {
                                Deleted,
                                Index,
                                Question,
                                Required,
                                Type,
                                Ulid,
                                OptionList {
                                    AnswerCount,
                                    Deleted,
                                    Index,
                                    Type,
                                    Ulid,
                                    Value
                                }
                            }
                        }
                        UserForm {
                            DateTimeSubmitted,
                            FormTemplateUlid,
                            Header,
                            LogoUrl,
                            Name,
                            ThemeColor,
                            Subheader,
                            Title,
                            Ulid,
                            QuestionList {
                                FormTemplateQuestionUlid,
                                Index,
                                Ulid,
                                Type,
                                Required,
                                Question,
                                OptionList {
                                    Answer,
                                    FormTemplateOptionUlid,
                                    Index,
                                    Type,
                                    Ulid,
                                    Value
                                }
                            }
                        }
                    }
                }
            }`,
        };
        return new Observable<ApiResponseModel<ShortCodeModel>>((subscriber) => {
            this._axiosInstance.pipe(take(1)).subscribe((axiosInstance: AxiosInstance) => {
                axiosInstance
                    .post(`${this._onlineFormsServiceUrl}/graphql`, body)
                    .then((response: any) => {
                        if (response.data.errors) {
                            subscriber.next(
                                this.handleApiError({
                                    StatusMessage: response.data.errors[0].message,
                                })
                            );
                        } else {
                            subscriber.next(this.handleApiData(ShortCodeModel.deserialize(response.data.data.getFormsByShortCode)));
                        }
                    })
                    .catch((err: any) => {
                        const errorResponse = this.handleApiError(err);
                        subscriber.next(errorResponse);
                    })
                    .finally(() => {
                        subscriber.complete();
                    });
            });
        });
    }

    public getFormTemplateByUlid(formTemplateUlid: string): Observable<ApiResponseModel<DsformModel>> {
        const body = {
            query: `query forTemplateGetQuery {
                formTemplateGet(input:{
                    Ulid:\"${formTemplateUlid}\"
                }) {
                    DateTimeCreated,
                    DateTimeModified,
                    Header,
                    LogoUrl,
                    Name,
                    ResponseCount,
                    Status,
                    Subheader,
                    ThemeColor,
                    Title,
                    Ulid,
                    Questions {
                        Deleted,
                        Index,
                        Question,
                        Required,
                        Type,
                        Ulid,
                        OptionList {
                            AnswerCount,
                            Deleted,
                            Index,
                            Type,
                            Ulid,
                            Value
                        }
                    }
                }
            }`,
        };
        return new Observable<ApiResponseModel<DsformModel>>((subscriber) => {
            this._axiosInstance.pipe(take(1)).subscribe((axiosInstance: AxiosInstance) => {
                axiosInstance
                    .post(`${this._onlineFormsServiceUrl}/graphql`, body)
                    .then((response: any) => {
                        if (response.data.errors) {
                            subscriber.next(
                                this.handleApiError({
                                    StatusMessage: response.data.errors[0].message,
                                })
                            );
                        } else {
                            subscriber.next(this.handleApiData(DsformModel.deserialize(response.data.data.formTemplateGet)));
                        }
                    })
                    .catch((err: any) => {
                        const errorResponse = this.handleApiError(err);
                        subscriber.next(errorResponse);
                    })
                    .finally(() => {
                        subscriber.complete();
                    });
            });
        });
    }
    // form object needs to represent a form template, so id is a form template ulid
    public submitUserForm(form: DsformModel, shortCode: string): Observable<ApiResponseModel<DsformModel>> {
        const body = {
            query: `mutation submitShortCodeUserMutation {
                userFormSubmit(input: {
                    ShortCode: \"${shortCode}\",
                    UserForm: {
                        FormTemplateUlid: \"${form.id}\",
                        QuestionList:[
                            ${form.questions.reduce((aggregate, val) => {
                                return `${aggregate}${aggregate ? ',' : ''}
                                    {
                                        FormTemplateQuestionUlid: \"${val.id}\",
                                        OptionList:[
                                            ${val.optionList?.reduce((agg2, val2) => {
                                                return `${agg2}${agg2 ? ',' : ''}
                                            {
                                                FormTemplateOptionUlid:\"${val2.id !== undefined ? val2.id : ''}\",
                                                Answer:\"${
                                                    typeof val2.answer === 'string' ? JSON.stringify(val2.answer).slice(1, -1) : val2.answer
                                                }\"
                                            }`;
                                            }, '')}
                                        ]
                                    }`;
                            }, '')}
                        ]
                    }
                }) {
                    DateTimeSubmitted,
                    FormTemplateUlid,
                    Header,
                    LogoUrl,
                    Name,
                    Subheader,
                    ThemeColor,
                    Title,
                    Ulid,
                    QuestionList {
                        FormTemplateQuestionUlid,
                        Question,
                        Required,
                        Type,
                        Ulid,
                        Index,
                        OptionList {
                            Answer,
                            FormTemplateOptionUlid,
                            Index,
                            Ulid,
                            Value,
                            Type
                        }
                    }
                }
            }`,
        };
        return new Observable<ApiResponseModel<DsformModel>>((subscriber) => {
            this._axiosInstance.pipe(take(1)).subscribe((axiosInstance: AxiosInstance) => {
                axiosInstance
                    .post(`${this._onlineFormsServiceUrl}/graphql`, body)
                    .then((response: any) => {
                        if (response.data.errors) {
                            subscriber.next(
                                this.handleApiError({
                                    StatusMessage: response.data.errors[0].message,
                                })
                            );
                        } else {
                            subscriber.next(this.handleApiData(DsformModel.deserialize(response.data.data.userFormSubmit)));
                        }
                    })
                    .catch((err: any) => {
                        const errorResponse = this.handleApiError(err);
                        subscriber.next(errorResponse);
                    })
                    .finally(() => {
                        subscriber.complete();
                    });
            });
        });
    }

    public getSignedUrl(
        filename: string,
        type: FileUploadType,
        formTemplateUlid: string
    ): Observable<ApiResponseModel<GetSignedUrlResponse>> {
        const body = {
            query: `query getUrlSigned {getSignedUrl(input: {FileName: ${JSON.stringify(
                filename
            )}, Type: ${type}, FormTemplateUlid: "${formTemplateUlid}"}) {Key, SignedUrl}}`,
        };
        return new Observable<ApiResponseModel<GetSignedUrlResponse>>((subscriber) => {
            this._axiosInstance.pipe(take(1)).subscribe((axiosInstance: AxiosInstance) => {
                axiosInstance
                    .post(`${this._onlineFormsServiceUrl}/graphql`, body)
                    .then((response: any) => {
                        if (response.data.errors) {
                            subscriber.next(
                                this.handleApiError({
                                    StatusMessage: response.data.errors[0].message,
                                })
                            );
                        } else {
                            subscriber.next(
                                this.handleApiData<GetSignedUrlResponse>({
                                    SignedUrl: response.data.data.getSignedUrl.SignedUrl,
                                    Key: response.data.data.getSignedUrl.Key,
                                })
                            );
                        }
                    })
                    .catch((err: any) => {
                        const errorResponse = this.handleApiError(err);
                        subscriber.next(errorResponse);
                    })
                    .finally(() => {
                        subscriber.complete();
                    });
            });
        });
    }

    public upload(urlToPut: string, blob: any): Observable<ApiResponseModel<boolean>> {
        return new Observable<ApiResponseModel<boolean>>((subscriber) => {
            this._axiosInstance.pipe(take(1)).subscribe((axiosInstance: AxiosInstance) => {
                axiosInstance
                    .put(urlToPut, blob, { headers: {} })
                    .then(() => {
                        subscriber.next(this.handleApiData<boolean>(true));
                    })
                    .catch((err: any) => {
                        subscriber.next(this.handleApiError(err));
                    })
                    .finally(() => {
                        subscriber.complete();
                    });
            });
        });
    }
}
