<div class="element-form-container dsforms-element-client-info-container" formGroupName="{{ formGroupName }}" qa="client info-element">
    <div class="client-info-question element-question-static" qa="client-info-questoin">
        <span class="question-content">{{ question }}</span>
        <span class="dsform-required-indicator" *ngIf="required">*</span>
    </div>
    <mat-error qa="error" *ngIf="hasError">All fields except birth year are required. </mat-error>
    <div class="client-info-form-field-wrapper">
        <div class="client-info-option" *ngFor="let option of options; index as i">
            <div class="client-info-form-field-row" *ngIf="option.type === 'firstName'">
                <mat-form-field class="first-name-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="First Name"
                        formControlName="firstName"
                        [value]="option.value"
                        qa="client-info-first-name"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'lastName'">
                <mat-form-field class="last-name-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="Last Name"
                        formControlName="lastName"
                        [value]="option.value"
                        qa="client-info-last-name"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'phone'">
                <mat-form-field class="phone-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="Mobile Phone"
                        formControlName="phone"
                        [value]="option.value"
                        qa="client-info-phone-number"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'email'">
                <mat-form-field class="email-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="Email"
                        [value]="option.value"
                        formControlName="email"
                        qa="client-info-email"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'address'">
                <mat-form-field class="address-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="Address"
                        formControlName="address"
                        [value]="option.value"
                        qa="client-info-address"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'city'">
                <mat-form-field class="city-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="City / Town"
                        formControlName="city"
                        [value]="option.value"
                        qa="client-info-city"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'state'">
                <mat-form-field class="state-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="State / Province"
                        formControlName="state"
                        [value]="option.value"
                        qa="client-info-state"
                    />
                </mat-form-field>
            </div>
            <div class="client-info-form-field-row" *ngIf="option.type === 'zip'">
                <mat-form-field class="zip-field element-answer" floatLabel="never">
                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        maxlength="50"
                        placeholder="Postal Code"
                        formControlName="zip"
                        [value]="option.value"
                        qa="client-info"
                    />
                </mat-form-field>
            </div>
            <div
                class="client-info-form-field-row dsforms-client-info-birthday-wrapper element-answer"
                *ngIf="option.type === 'birthday'"
                formGroupName="birthday"
            >
                <span class="birthday-label element-answer">Birthday</span>
                <div class="multi-field-row">
                    <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer month" floatLabel="never">
                        <mat-select formControlName="month" placeholder="Month" [(value)]="monthValue" qa="client-birth-month">
                            <mat-option *ngFor="let option of monthOptions; index as i" [value]="option" qa="month-{{ option }}">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer month" floatLabel="never">
                        <select
                            formControlName="month"
                            matNativeControl
                            placeholder="Month"
                            (change)="monthValueChange()"
                            qa="native-client-birth-month"
                        >
                            <option qa="empty-native-client-birth-month" selected disabled hidden></option>
                            <option *ngFor="let option of monthOptions; index as i" [ngValue]="option" qa="native-month-{{ option }}">
                                {{ option }}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer day" floatLabel="never">
                        <mat-select formControlName="day" placeholder="Day" [(value)]="dayValue" qa="client-birth-day">
                            <mat-option *ngFor="let option of dayOptions; index as i" [value]="option" qa="day-{{ option }}">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer day" floatLabel="never">
                        <select formControlName="day" matNativeControl placeholder="Day" qa="native-client-birth-day">
                            <option qa="empty-native-client-birth-day" selected disabled hidden></option>
                            <option *ngFor="let option of dayOptions; index as i" [ngValue]="option" qa="native-day-{{ option }}">
                                {{ option }}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer year" floatLabel="never">
                        <mat-select formControlName="year" placeholder="Year" [(value)]="yearValue" qa="client-birth-year">
                            <mat-option *ngFor="let option of yearOptions; index as i" [value]="option" qa="year-{{ option }}">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer year" floatLabel="never">
                        <select
                            formControlName="year"
                            matNativeControl
                            placeholder="Year"
                            (change)="yearValueChange()"
                            qa="native-client-birth-year"
                        >
                            <option qa="empty-native-client-birth-year" selected disabled hidden></option>
                            <option *ngFor="let option of yearOptions; index as i" [ngValue]="option" qa="native-year-{{ option }}">
                                {{ option }}
                            </option>
                        </select>
                    </mat-form-field>
                </div>
            </div>
            <div
                class="client-info-form-field-row dsforms-drop-downs element-answer"
                *ngIf="option.type === 'remindersAndPromotions'"
                formGroupName="remindersAndPromotions"
            >
                <span class="remindersAndPromotions-label element-answer">Receive Reminders By</span>
                <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer reminders" floatLabel="never">
                    <mat-select
                        placeholder="Select an Option"
                        [(value)]="reminderValue"
                        formControlName="reminders"
                        qa="reminders-dropdown"
                    >
                        <mat-option qa="option-text" [value]="receiveRemindersBy.None"> None </mat-option>
                        <mat-option qa="option-text" [value]="receiveRemindersBy.EmailAndtText"> Email and Text </mat-option>
                        <mat-option qa="option-email" [value]="receiveRemindersBy.EmailOnly"> Email Only </mat-option>
                        <mat-option qa="option-text" [value]="receiveRemindersBy.TextOnly"> Text Only </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer reminders" floatLabel="never">
                    <select matNativeControl placeholder="Select an Option" formControlName="reminders" qa="native-reminders-dropdown">
                        <option qa="native-option-text" [ngValue]="receiveRemindersBy.None">None</option>
                        <option qa="native-option-text" [ngValue]="receiveRemindersBy.EmailAndtText">Email and Text</option>
                        <option qa="native-option-email" [ngValue]="receiveRemindersBy.EmailOnly">Email Only</option>
                        <option qa="native-option-text" [ngValue]="receiveRemindersBy.TextOnly">Text Only</option>
                    </select>
                </mat-form-field>
                <span class="remindersAndPromotions-label element-answer"
                    ><span class="comma-separator">, </span>Receive Promotions By</span
                >
                <mat-form-field *ngIf="!deviceService.isMobile()" class="element-answer promotions" floatLabel="never">
                    <mat-select
                        placeholder="Select an Option"
                        [(value)]="promotionValue"
                        formControlName="promotions"
                        qa="promotions-dropdown"
                    >
                        <mat-option qa="option-text" [value]="receivePromotionsBy.None"> None </mat-option>
                        <mat-option qa="option-text" [value]="receivePromotionsBy.EmailAndtText"> Email and Text </mat-option>
                        <mat-option qa="option-email" [value]="receivePromotionsBy.EmailOnly"> Email Only </mat-option>
                        <mat-option qa="option-text" [value]="receivePromotionsBy.TextOnly"> Text Only </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="deviceService.isMobile()" class="element-answer promotions" floatLabel="never">
                    <select matNativeControl placeholder="Select an Option" formControlName="promotions" qa="native-promotions-dropdown">
                        <option qa="native-option-text" [ngValue]="receivePromotionsBy.None">None</option>
                        <option qa="native-option-text" [ngValue]="receivePromotionsBy.EmailAndtText">Email and Text</option>
                        <option qa="native-option-email" [ngValue]="receivePromotionsBy.EmailOnly">Email Only</option>
                        <option qa="native-option-text" [ngValue]="receivePromotionsBy.TextOnly">Text Only</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
