import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

@Component({
    selector: 'dsf-dsforms-radio-button',
    templateUrl: './dsforms-radio-button.component.html',
    styleUrls: ['./dsforms-radio-button.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsRadioButtonComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public options: DsformModelQuestionOption[];
    public hasError = false;

    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }
    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        const selectedChoice = this.data?.question?.optionList?.find((o) => o.answer)?.value || '';
        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.formGroup = new UntypedFormGroup({
                radio: new UntypedFormControl(selectedChoice, [Validators.required]),
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                radio: new UntypedFormControl(selectedChoice),
            });
        }
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);

        if (this.data) {
            this.options = this.data.question?.optionList?.filter((x) => x.value);
        }
    }

    public generateModel(): DsformModelQuestion {
        const answer = this.formGroup?.get('radio')?.value;
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        this.data?.question?.optionList?.forEach((o: any) => {
            o.answer = o.value === answer ? true : false;
        });
        return this.data?.question;
    }

    private validate() {
        const answer = this.formGroup?.get('radio')?.value;
        if (answer) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }
}
