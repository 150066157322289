import { Injectable } from '@angular/core';
import { ApiResponseModel } from '../models/api-response.model';

@Injectable()
export class ApiResponseHandlerService {
    constructor() {}

    handleApiData<T = any>(data: T): ApiResponseModel<T> {
        return new ApiResponseModel<T>({ data });
    }

    handleApiError<T = any>(error: any, errorData?: T): ApiResponseModel<T> {
        // Error response defined by backend
        return new ApiResponseModel<T>({
            error: true,
            errorCode: error.StatusCode,
            statusMessage: error.StatusMessage,
            httpStatus: error.HttpStatus,
            errorData: errorData,
        });
    }
}
