import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlContainer, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { DsformElementData, DsformElementModel, DsformModelQuestion } from '../../../dsform.model';
import { DsformsCheckboxComponent } from '../populate-form-element/checkbox/dsforms-checkbox.component';
import { DsformsClientInfoComponent } from './client-info/dsforms-client-info.component';
import { DsformsDateComponent } from './date/dsforms-date.component';
import { DsformsDropdownComponent } from './dropdown/dsforms-dropdown.component';
import { DsformsFileUploadComponent } from './file-upload/dsforms-file-upload.component';
import { DsformsHeaderComponent } from './header/dsforms-header.component';
import { DsformsLongAnswerComponent } from './long-answer/dsforms-long-answer.component';
import { DsformsParagraphComponent } from './paragraph/dsforms-paragraph.component';
import { DsformsRadioButtonComponent } from './radio-button/dsforms-radio-button.component';
import { DsformsScaleComponent } from './scale/dsforms-scale.component';
import { DsformsSeparatorComponent } from './separator/dsforms-separator.component';
import { DsformsShortAnswerComponent } from './short-answer/dsforms-short-answer.component';
import { DsformsSignatureComponent } from './signature/dsforms-signature.component';
import { DsformsTimeComponent } from './time/dsforms-time.component';

export interface DynamicElementComponent {
    data: DsformElementData;
    themeColor: string;
    generateModel(): DsformModelQuestion | void;
}

@Component({
    selector: 'dsf-dsforms-populate-form-element',
    templateUrl: './populate-form-element.component.html',
    styleUrls: ['./populate-form-element.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsPopulateFormElementComponent implements OnInit, AfterViewInit {
    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) public dynamicComponentContainer!: ViewContainerRef;

    // the index of components in this array should match the value of the corresponding enum for that component type
    // this way we can do this._componentClassTypes[this.element.type] to dynamically get the correct component type
    private _componentClassTypes: Type<DynamicElementComponent>[] = [
        DsformsShortAnswerComponent,
        DsformsLongAnswerComponent,
        DsformsClientInfoComponent,
        DsformsRadioButtonComponent,
        DsformsCheckboxComponent,
        DsformsDropdownComponent,
        DsformsScaleComponent,
        DsformsDateComponent,
        DsformsTimeComponent,
        DsformsFileUploadComponent,
        DsformsSignatureComponent,
        DsformsHeaderComponent,
        DsformsParagraphComponent,
        DsformsSeparatorComponent,
    ];
    private _dynamicComponent!: DynamicElementComponent;

    @Input() public element: DsformElementModel;
    @Input() public themeColor: string;
    public elementFormGroup!: UntypedFormGroup;

    public controlName!: string;

    constructor(private dsform: FormGroupDirective, private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {
        if (!this.isValidParams()) {
            return;
        }
    }

    ngAfterViewInit() {
        if (!this.isValidParams()) {
            return;
        }

        // dynamically generate the selected component.  We need to do this here so the view is instiated and we have our
        // viewRef loaded, but this is also a bad place in the angular lifecycle to introduce something new to change
        // detection (the dynamic component), so throw this chunk of logic into a 0 timeout so it's executed at the end
        // of the current stack and thus outside of the change detection lifecycle
        setTimeout(() => {
            const targetComponentClassType = this._componentClassTypes[this.element.type];
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(targetComponentClassType);
            const componentRef = this.dynamicComponentContainer.createComponent<DynamicElementComponent>(componentFactory);
            this._dynamicComponent = componentRef.instance;
            // note that for components generated in this way, the Input/Outputs for the component aren't being automatically
            // tracked.  For example, if this.selected changes, we have to manually update the selected property on the
            // component - it won't just automatically "bubble down"
            this._dynamicComponent.data = this.element.data;
            this._dynamicComponent.themeColor = this.themeColor;
        });
    }

    private isValidParams(): boolean {
        if (!this._componentClassTypes[this.element?.type]) {
            // TODO: Something's wrong (probably the array isn't setup correctly)
            return false;
        }

        if (!this.element) {
            // TODO: Some required information wasn't provided
            return false;
        }

        return true;
    }

    public generateModel(): DsformModelQuestion {
        return new DsformModelQuestion({
            ...this._dynamicComponent.generateModel(),
        });
    }
}
