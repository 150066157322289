import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DsformElementData, DsformModelQuestion, DsformModelQuestionOption } from 'src/app/pages/dsforms/dsform.model';
import { DynamicElementComponent } from '../populate-form-element.component';

export enum ScaleRatingType {
    StarRating,
    ScaleRating5,
    ScaleRating10,
}

@Component({
    selector: 'dsf-dsforms-scale',
    templateUrl: './dsforms-scale.component.html',
    styleUrls: ['./dsforms-scale.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsScaleComponent implements DynamicElementComponent, OnInit, OnDestroy {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;
    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public scaleRatingType = ScaleRatingType;
    public hasError = false;
    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }
    public disabled = false;

    public get ratingType(): ScaleRatingType {
        const rating = this.data?.question?.optionList?.find((x: DsformModelQuestionOption) => x.type === 'ratingType')?.value;
        if (rating !== undefined) {
            switch (rating) {
                case 'StarRating':
                    return ScaleRatingType.StarRating;
                case 'ScaleRating5':
                    return ScaleRatingType.ScaleRating5;
                case 'ScaleRating10':
                    return ScaleRatingType.ScaleRating10;
                default:
                    return ScaleRatingType.StarRating;
            }
        }
        return ScaleRatingType.StarRating;
    }

    public get startLabel(): string {
        const val = this.data?.question?.optionList?.find((x: DsformModelQuestionOption) => x.type === 'startLabel')?.value;
        return val ? val : '';
    }

    public get endLabel(): string {
        const val = this.data?.question?.optionList?.find((x: DsformModelQuestionOption) => x.type === 'endLabel')?.value;
        return val ? val : '';
    }

    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }
    private _ngUnsubscribe: Subject<any> = new Subject();

    constructor(private elementForm: FormGroupDirective) {}

    ngOnInit(): void {
        const answer = this.data?.question?.optionList[0]?.answer || '';
        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.formGroup = new UntypedFormGroup({
                rating: new UntypedFormControl(answer, [Validators.required]),
            });
        } else {
            this.formGroup = new UntypedFormGroup({
                rating: new UntypedFormControl(answer),
            });
        }
        this.elementForm.form.addControl(this.formGroupName, this.formGroup);
        this.formGroup.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe(() => {
            if (this.formGroup.disabled && !this.disabled) {
                this.disabled = true;
            } else if (!this.formGroup.disabled && this.disabled) {
                this.disabled = false;
            }
        });
    }

    ngOnDestroy(): void {
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    public withRatedNumber(i: number): boolean {
        const val = this.formGroup?.get('rating')?.value as number;
        if (val && i <= val) {
            return true;
        }
        return false;
    }

    public sameAsRatedNumber(i: number): boolean {
        const val = this.formGroup?.get('rating')?.value as number;
        if (val && i === val) {
            return true;
        }
        return false;
    }

    public rating(i: number): void {
        if (this.disabled) {
            return;
        }
        this.formGroup?.get('rating')?.setValue(i);
    }

    public rated(): boolean {
        const val = this.formGroup?.get('rating')?.value as number;
        if (val) {
            return true;
        }
        return false;
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }
        this.data?.question?.optionList?.forEach((o: any) => {
            o.answer = o.type === 'ratingType' ? (this.formGroup?.get('rating')?.value as number) : undefined;
        });
        return this.data?.question;
    }

    private validate() {
        const answer = this.formGroup?.get('rating')?.value;
        if (answer) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
        }
    }
}
