import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient as CommonHttpClient } from '@angular/common/http';
import { EnvConfigLoaderService } from './services/env-config-loader.service';
/* Kendo UI imports */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DsformsModule } from './pages/dsforms/dsforms.module';
import { DsformApiService } from './services/api/dsform/dsform-api.service';
import { SnackBarService } from './services/snackbar.service';
import { DeviceService } from './services/device.service';
import { PlatformModule } from '@angular/cdk/platform';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule, DsformsModule, PlatformModule],
    providers: [
        CommonHttpClient,
        EnvConfigLoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: EnvConfigLoaderService) => () => configService.loadEnvConfig().toPromise(),
            deps: [EnvConfigLoaderService],
            multi: true,
        },
        DsformApiService,
        SnackBarService,
        DeviceService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
