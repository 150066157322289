import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective, UntypedFormGroup, Validators } from '@angular/forms';
import {
    DsformElementData,
    DsformFileUploadAllowedExt,
    DsformModelQuestion,
    DsformModelQuestionOption,
    FileUploadModel,
} from 'src/app/pages/dsforms/dsform.model';
import { FileRawType } from 'src/app/pages/dsforms/dsform.service';
import { DynamicElementComponent } from '../populate-form-element.component';

const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; //10 MB
@Component({
    selector: 'dsf-dsforms-file-upload',
    templateUrl: './dsforms-file-upload.component.html',
    styleUrls: ['./dsforms-file-upload.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DsformsFileUploadComponent implements DynamicElementComponent, OnInit {
    @Input() public data: DsformElementData;
    @Input() public themeColor: string;

    @ViewChild('fileDropRef', { read: ElementRef }) fileDropRef: ElementRef;

    public formGroupName: string;
    public formGroup: UntypedFormGroup;
    public hasError = false;
    public errorMsg: string;
    public fileOverDropZone: boolean;
    public viewMode = false;
    public uploadedFiles: FileUploadModel[];
    public fileUploadsControl: UntypedFormControl;

    public fileExtFilter: string;
    private _fileAllowedExtensions: string[];

    constructor(private elementForm: FormGroupDirective) {}

    public get required(): boolean {
        const val = this.data?.question?.required;
        return val ? val : false;
    }

    public get question(): string {
        const val = this.data?.question?.question;
        return val ? val : 'Question';
    }

    ngOnInit(): void {
        this.formGroupName = `${this.data?.question?.id}`;
        if (this.data?.question?.required) {
            this.fileUploadsControl = new UntypedFormControl('', [Validators.required]);
        } else {
            this.fileUploadsControl = new UntypedFormControl('');
        }
        this.formGroup = new UntypedFormGroup({
            fileUploads: this.fileUploadsControl,
        });

        this.elementForm.form.addControl(this.formGroupName, this.formGroup);

        this.uploadedFiles = [];

        if (this.data) {
            if (this.data?.question?.optionList?.length > 0) {
                this.viewMode = true;
                this.data.question.optionList.forEach((fileOption) => {
                    const file: FileUploadModel = {
                        name: fileOption.value,
                        type: 'type',
                        dataUrl: fileOption.answer as string,
                        isImage: this.isFileAnImage(fileOption.value),
                    };
                    this.uploadedFiles.push(file);
                });
            }
        }

        this._fileAllowedExtensions = [];
        for (const fileExt in DsformFileUploadAllowedExt) {
            this._fileAllowedExtensions.push(DsformFileUploadAllowedExt[fileExt]);
        }

        this.fileExtFilter = this._fileAllowedExtensions.join(', ');
    }

    public setFileOverDropZone(val: boolean) {
        this.fileOverDropZone = val;
    }

    public onFileDropped(selectedFiles: FileList) {
        this.hasError = false;
        if (selectedFiles && selectedFiles.length) {
            this.handleSelectedFiles(selectedFiles);
        }
    }

    public fileBrowseHandler(event: any) {
        const files = event.target.files;
        this.hasError = false;

        if (files && files.length) {
            this.handleSelectedFiles(files);
        }
    }

    public removeFile(fileToRemove: FileUploadModel) {
        this.uploadedFiles = this.uploadedFiles.filter((file) => file.name !== fileToRemove.name);
        this.fileDropRef.nativeElement.value = '';
    }

    public generateModel(): DsformModelQuestion {
        if (this.data?.question?.required) {
            this.validate();
            this.formGroup.valueChanges.subscribe(() => {
                this.validate();
            });
        }

        this.data.question.optionList = [];
        this.uploadedFiles.forEach((file) => {
            this.data.question.optionList.push(
                new DsformModelQuestionOption({
                    type: FileRawType.FileUpload,
                    value: file.name,
                    answer: file.dataUrl,
                })
            );
        });

        return this.data?.question;
    }

    private validate() {
        if (this.uploadedFiles.length > 0) {
            this.elementForm.form.controls[this.formGroupName].setErrors(null);
            this.hasError = false;
        } else {
            this.elementForm.form.controls[this.formGroupName].setErrors({ required: true });
            this.hasError = true;
            this.errorMsg = 'At least one file must be uploaded.';
        }
    }

    private isFileAnImage(fileName: string): boolean {
        const fileExt = fileName.split('.').pop();

        const imageAllowedExtensions: string[] = [];
        imageAllowedExtensions.push(DsformFileUploadAllowedExt.png);
        imageAllowedExtensions.push(DsformFileUploadAllowedExt.jpeg);
        imageAllowedExtensions.push(DsformFileUploadAllowedExt.jpg);
        imageAllowedExtensions.push(DsformFileUploadAllowedExt.img);
        imageAllowedExtensions.push(DsformFileUploadAllowedExt.bmp);

        return imageAllowedExtensions.some((e) => e === '.' + fileExt);
    }

    private checkSelectedFiles(selectedFiles: FileList): boolean {
        const filesWithSizeOverLimit: string[] = [];
        const filesWithNotAllowedExtension: string[] = [];
        const filesWithSameName: string[] = [];

        this.hasError = false;

        for (let i = 0; i < selectedFiles.length; i++) {
            const currFileName = selectedFiles[i].name;

            if (selectedFiles[i].size > MAX_FILE_SIZE_BYTES) {
                filesWithSizeOverLimit.push(currFileName);
            }

            const currFileExt = currFileName.split('.').pop();
            if (!this._fileAllowedExtensions.some((x) => x === '.' + currFileExt)) {
                filesWithNotAllowedExtension.push(currFileName);
            }

            if (this.uploadedFiles.some((f) => f.name === currFileName)) {
                filesWithSameName.push(currFileName);
            }
        }

        let fileNames = '';
        if (filesWithSizeOverLimit.length > 0 || filesWithSameName.length > 0 || filesWithNotAllowedExtension.length > 0) {
            this.hasError = true;

            if (filesWithSizeOverLimit.length > 0) {
                fileNames = filesWithSizeOverLimit.join(' | ');
                this.errorMsg = `Following files "${fileNames}" are greater than 10MB (file size limit).`;
            } else if (filesWithNotAllowedExtension.length > 0) {
                fileNames = filesWithNotAllowedExtension.join(' | ');
                this.errorMsg = `Unable to upload files \"${fileNames}\". This file type is not supported.`;
            } else if (filesWithSameName.length > 0) {
                fileNames = filesWithSameName.join(' | ');
                this.errorMsg = `Unable to upload files \"${fileNames}\". There are already uploaded files with the same name.`;
            }

            return false;
        }
        return true;
    }

    private handleSelectedFiles(selectedFiles: FileList) {
        this.hasError = false;
        if (selectedFiles && selectedFiles.length) {
            if (selectedFiles.length > 3 || this.uploadedFiles.length + selectedFiles.length > 3) {
                this.hasError = true;
                this.errorMsg = `You can't upload more than 3 files.`;
                return;
            }

            if (!this.checkSelectedFiles(selectedFiles)) {
                return;
            }

            for (let i = 0; i < selectedFiles.length; i++) {
                const currFile = selectedFiles[i];

                const reader = new FileReader();
                reader.readAsDataURL(currFile);

                reader.onload = (_event) => {
                    const file: FileUploadModel = {
                        name: currFile.name,
                        type: currFile.type,
                        dataUrl: reader.result as string,
                        isImage: this.isFileAnImage(currFile.name),
                    };
                    this.uploadedFiles.push(file);

                    // drag & drop doesn't appear to set the form control's value which then fails validation (on required uploads)
                    // this ensures the form control has a value even on drag & drop
                    this.fileUploadsControl.patchValue(file.name, { emitModelToViewChange: false });
                };
            }
        }
    }
}
